/* eslint-disable linebreak-style */
import React, {Fragment, useState} from 'react';
import {useSnackbar} from 'notistack';
import {Button, Card, CircularProgress, Grid, Tooltip, Typography} from '@material-ui/core';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import './PieceJointe.scss';
import DeleteIcon from '@mui/icons-material/Delete';

const PiecesJointePage = ({
  acceptedMimeType = "image/*,audio/*,video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/xml,application/xml,application/xhtml+xml,text/html, text/plain,application/x-rar-compressed, application/zip, application/octet-stream, application/x-zip-compressed,application/x-7z-compressed, application/x-tar, application/gzip, application/x-gzip, application/x-gtar, application/x-tgz",
  multipleFileSelect= false,
  maxFiles  = -1,
  initialFiles = [],
  onChange, inputContent = 'Déposer le(s) fichier(s) ici'
}) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [files, setFiles] = useState(initialFiles);
  const [isLoading, setIsLoading] = useState(false);

  const suppressionPieceJointe = (fileDelete) => {
    setIsLoading(true);
    const filesTemp = files.filter(data => data.name !== fileDelete.name);
    setFiles(filesTemp);
    if(onChange !== undefined) {
      onChange(filesTemp);
    }
    setIsLoading(false);

    enqueueSnackbar('La pièce jointe a bien été supprimé!', {
      variant: 'success',
      autoHideDuration: 2000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    });
  };

  const cardPieceJointe = (file) => {
    let nom = file.name.substring(0, file.name.lastIndexOf('.'));

    const extension = file.name.substring(file.name.lastIndexOf('.'), file.name.length);

    if (nom.length > 10) {
      nom = nom.substr(0, 10);
    }

    return (
      <Card className="navbar-card" >
        <Grid container xs={12} style={{ maxHeight: '20%' }}>
          <Grid item xs={2} className="navbar-card-gridIcone">
            <div className="navbar-card-icone">{extension}</div>
          </Grid>
          <Grid item xs={8} className="navbar-card-gridIcone">
            <Typography className="navbar-card-title">{nom}</Typography>
          </Grid>
          <Grid xs={2} item className="navbar-card-gridIcone">
            <Tooltip title="Supprimer la pièce-jointe" placement="top">
              <Button size={'small'} onClick={() => { suppressionPieceJointe(file); }} color="primary"><DeleteIcon /></Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
    );
  };

  const handleChangeStatus = (file, status) => {

    if (status === 'preparing' && files.length !== maxFiles) {
      setIsLoading(true);
      let filesTemp = files;
      filesTemp.push(file.file);
      setFiles(filesTemp);
      file.remove();
      if(onChange !== undefined) {
        onChange(filesTemp);
      }

      enqueueSnackbar('La pièce jointe a bien été importée!', {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      setIsLoading(false);
    }

    if (status === 'rejected_file_type') {
      enqueueSnackbar('Fichier non autorisé', {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
    }
  };

  return (
      <Grid container spacing={3} style={{ minHeight: '250px', maxHeight: '250px' }}>
        <Grid item xs={6}>
          <Dropzone
            accept={acceptedMimeType}
            onChangeStatus={(file, status) => handleChangeStatus(file, status)}
            inputContent={(file, extra) => (extra.reject ? 'Fichier non autorisé' : inputContent)}
            inputWithFilesContent="Ajouter un fichier"
            canCancel={false}
            autoUpload
            multiple={multipleFileSelect}
            maxSizeBytes={60000 * 1024}
            disabled={files.length === maxFiles}
            styles={{
              dropzone: { width: '100%', minHeight: '98%', maxHeight: '200px', overflow: 'auto', border: '2px dashed #003b5e', backgroundColor: '#ffffff' },
              dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
              inputLabel: (file, extra) => (extra.reject ? { color: 'red' } : { color: '#003b5e', textAlign: 'center' }),
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ overflow: 'auto', height: '100%', minHeight: '240px', maxHeight: '240px', marginTop: '5px', padding: '5px' }}>
            {isLoading ? (
              <Grid container item xs justify="center" alignItems="center" style={{ height: '240px' }}>
                <CircularProgress className="circularProgress" size={60} />
              </Grid>
            ) : (
              <Grid container item xs direction="row">
                {files.map((file, index) => (
                  <Fragment key={`files-${index}`}>
                    {cardPieceJointe(file)}
                  </Fragment>
                ))}
              </Grid>
            )}
        </Grid>
      </Grid>
  );
};

export default PiecesJointePage;
