import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import {Dialog, DialogActions, DialogContent, DialogTitle, Slide} from '@material-ui/core';

import './Modal.scss';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Modal = ({
  className, fullScreen, isOpen, dialogFooter, dialogHeader, children,
  headerStyle, contentStyle, actionStyle, onClose, theme, maxWidth, disableBackdropClick,
}) => (
  <Dialog
    fullScreen={fullScreen}
    className={classnames(className, (theme ? `modal-${theme}` : ''))}
    maxWidth={maxWidth}
    TransitionComponent={Transition}
    onClose={onClose}
    open={isOpen}
    scroll="paper"
    aria-labelledby="dialogTitle"
    disableBackdropClick={disableBackdropClick}
  >
    {(dialogHeader ? (
      <DialogTitle className={classnames(headerStyle, (theme ? `modal-header-${theme}` : ''))}>
        {dialogHeader}
      </DialogTitle>
    ) : null)}
    {(children ? (
      <DialogContent className={classnames(contentStyle, (theme ? `modal-content-${theme}` : ''))}>
        {children}
      </DialogContent>
    ) : null)}
    {(dialogFooter ? (
      <DialogActions className={classnames(actionStyle, (theme ? `modal-footer-${theme}` : ''))}>
        {dialogFooter}
      </DialogActions>
    ) : null)}
  </Dialog>
);

Modal.defaultProps = {
  headerStyle: '',
  contentStyle: '',
  actionStyle: '',
  dialogHeader: null,
  dialogFooter: null,
  fullScreen: false,
  className: '',
  onClose: () => null,
  theme: '',
  maxWidth: 'sm',
  disableBackdropClick: true,
};

Modal.propTypes = {
  headerStyle: propTypes.string,
  contentStyle: propTypes.string,
  actionStyle: propTypes.string,
  dialogHeader: propTypes.node,
  dialogFooter: propTypes.node,
  isOpen: propTypes.bool.isRequired,
  fullScreen: propTypes.bool,
  className: propTypes.string,
  onClose: propTypes.func,
  theme: propTypes.string,
  maxWidth: propTypes.string,
  disableBackdropClick: propTypes.bool,
};

export default Modal;
