import React from 'react';
import '../../App.css';
import {Grid, Typography,} from '@material-ui/core';

const Validation = (props) => {

    return (
        <Grid container spacing={1} direction="column" className="form">
            <Grid item xs={12} className="section-title">
                <Typography className="page-title-validation" component="h3" variant="h5">
                    Confirmation
                </Typography>
            </Grid>
            <Grid container spacing={2} justify="space-around" className="section-content">
                <Grid item xs={11}>
                    <Typography className="text-confirmation">
                        Vos coordonnées ne seront pas utilisées à des fins commerciales, mais uniquement dans le cas où nous
                        aurions besoin d’intervenir afin de remettre en état votre raccordement à la fibre optique.
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography className="text-confirmation">
                        En remplissant ce formulaire, vous acceptez d'être contacté(e) <u>et</u> de vous rendre
                        disponible pour la remise en état du raccordement.
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography className="text-confirmation">
                        La transmission d’un signalement ne vaut pas reconnaissance automatique d’une responsabilité ou
                        d’une faute de l’installateur.
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography className="text-confirmation">
                        Une fois le formulaire validé, vous recevrez un mail de confirmation, avec
                        votre <u>numéro de dossier</u>. Merci de le conserver pour faciliter le traitement de votre dossier.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Validation;
