import * as Yup from 'yup';
import checkoutFormModel from './checkoutFormModel';

import {Constantes} from '../../services';

const {
  formField: {
    AccordDonnees,
    ProblemeNonReseau,
    ProblemeNonFai,
    Geoloc,
    X,
    Y,
    EstLogementProche,
    NumeroVoie,
    LibelleVoie,
    ComplementAdresse,
    CodePostal,
    Ville,
    ReferenceImb,
    TypeMalfacon,
    Tiers,
    DemarcheEnCours,
    ReferenceInterne,
    CommentaireInterne,
    DateInstallation,
    PresenceInstallation,
    ReferencePTO,
    EstOccupant,
    EstProprietaire,
    AccordProprietaire,
    PiecesJointes,
    PiecesJointesSupplementaires,
    Commentaire,
    StatutContact,
    Nom,
    Prenom,
    Mail,
    Telephone,
    Informe,
    NomSecondaire,
    PrenomSecondaire,
    MailSecondaire,
    TelephoneSecondaire,
    RGPD
  }
} = checkoutFormModel;


export default [
  Yup.object().shape({
    [AccordDonnees.name]: Yup.bool().oneOf([true], AccordDonnees.requiredErrorMsg).typeError("Veuillez sélectionner une valeur valide"),
    [ProblemeNonFai.name]: Yup.bool().oneOf([true], ProblemeNonFai.requiredErrorMsg).typeError("Veuillez sélectionner une valeur valide"),
    [ProblemeNonReseau.name]: Yup.bool().oneOf([true], ProblemeNonReseau.requiredErrorMsg).typeError("Veuillez sélectionner une valeur valide"),
  }),
  // Page Adresse
  Yup.object().shape({
    [Geoloc.name]: Yup.bool().required(`${Geoloc.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
    [X.name]: Yup.number().required(`${X.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
    [Y.name]: Yup.number().required(`${Y.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
    [EstLogementProche.name]: Yup.object().required(`${EstLogementProche.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
    [ReferenceImb.name]:Yup.string().required(`${ReferenceImb.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
    [NumeroVoie.name]:Yup.string().when([EstLogementProche.name],
      {
        is: (EstLogementProche) => EstLogementProche !== null && EstLogementProche.value === Constantes.EstLogementProche_LOGEMENTPROCHE_VAL,
        then: Yup.string().required(`${NumeroVoie.requiredErrorMsg}`),
        otherwise: Yup.string().notRequired()
      }),
    [LibelleVoie.name]:Yup.string().when([EstLogementProche.name],
      {
        is: (EstLogementProche) => EstLogementProche !== null && EstLogementProche.value === Constantes.EstLogementProche_LOGEMENTPROCHE_VAL,
        then: Yup.string().required(`${LibelleVoie.requiredErrorMsg}`),
        otherwise: Yup.string().notRequired()
      }),
    [ComplementAdresse.name]: Yup.string().notRequired(),
    [CodePostal.name]:Yup.string().when([EstLogementProche.name],
      {
        is: (EstLogementProche) => EstLogementProche !== null && EstLogementProche.value === Constantes.EstLogementProche_LOGEMENTPROCHE_VAL,
        then: Yup.string().required(`${CodePostal.requiredErrorMsg}`),
        otherwise: Yup.string().notRequired()
      }),
    [Ville.name]:Yup.string().when([EstLogementProche.name],
      {
        is: (EstLogementProche) => EstLogementProche !== null && EstLogementProche.value === Constantes.EstLogementProche_LOGEMENTPROCHE_VAL,
        then: Yup.string().required(`${Ville.requiredErrorMsg}`),
        otherwise: Yup.string().notRequired()
      }),
  }),
  // Page Description
  Yup.object().shape({
    [TypeMalfacon.name]: Yup.object().required(`${TypeMalfacon.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
    [Tiers.name]: Yup.object().required(`${Tiers.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
    [DemarcheEnCours.name]: Yup.bool().typeError("Veuillez sélectionner une valeur valide"),
    [ReferenceInterne.name]: Yup.string().typeError("Veuillez sélectionner une valeur valide"),
    [CommentaireInterne.name]: Yup.string().typeError("Veuillez sélectionner une valeur valide"),
    [PresenceInstallation.name]: Yup.string().typeError("Veuillez sélectionner une valeur valide"),
    [ReferencePTO.name]: Yup.object().required(`${ReferencePTO.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
    [DateInstallation.name]:Yup.date().nullable().default(null).typeError("Veuillez sélectionner une valeur valide"),
    [PiecesJointes.name]:Yup.array().required(`${PiecesJointes.requiredErrorMsg}`).test({
      message: `${PiecesJointes.lengthErrorMsg}`,
      test: arr => arr.length === 1,
    }),
    [PiecesJointesSupplementaires.name]:Yup.array().required(`${PiecesJointesSupplementaires.requiredErrorMsg}`).when([PiecesJointes.name],
      (PiecesJointes) => {
        let pjName = '';
        if (PiecesJointes.length === 1) {
          pjName = PiecesJointes[0].name;
        }
        return Yup.array().test('sameFileName', `${PiecesJointesSupplementaires.sameNameErrorMsg}`, (PiecesJointesSupplementaires) => {
          const pjSuppNames = PiecesJointesSupplementaires.map(pjSupp => pjSupp.name);
          const pjSuppNamesUnique = (new Set(pjSuppNames)).size;
          return (pjSuppNamesUnique === pjSuppNames.length) && !pjSuppNames.includes(pjName);
        });
      }),
    [Commentaire.name]:Yup.string().required(`${Commentaire.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
  }),
  // Page Coordonnées
  Yup.object().shape({
    [EstOccupant.name]: Yup.object().required(`${EstOccupant.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
    [EstProprietaire.name]: Yup.object().required(`${EstProprietaire.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
    [AccordProprietaire.name]: Yup.object().when([EstProprietaire.name],
      {
        is: (estProprietaire) => estProprietaire !== undefined && estProprietaire.value === 0,
        then: Yup.object().required(`${AccordProprietaire.requiredErrorMsg}`),
        otherwise: Yup.object().notRequired()
      }).typeError("Veuillez sélectionner une valeur valide"),
    [StatutContact.name]: Yup.object().when([EstProprietaire.name, EstOccupant.name],
      {
        is: (estProprietaire, estOccupant) => (estProprietaire !== undefined && estProprietaire.value === 0) && (estOccupant !== undefined && estOccupant.value === 0),
        then: Yup.object().required(`${StatutContact.requiredErrorMsg}`),
        otherwise: Yup.object().notRequired()
      }).typeError("Veuillez sélectionner une valeur valide"),
    [Nom.name]: Yup.string().required(`${Nom.requiredErrorMsg}`),
    [Prenom.name]: Yup.string().required(`${Prenom.requiredErrorMsg}`),
    [Mail.name]: Yup.string().email(`${Mail.invalidErrorMsg}`).required(`${Prenom.requiredErrorMsg}`),
    [Telephone.name]: Yup.string().matches(/(^(0|\+33|0033)[1-9][0-9]{8}$)|(^(\+41|0041)[0-9]{9}$)|(^(\+49|0049)[0-9]{9,11}$)/, {
        message: `${Telephone.invalidErrorMsg}`,
      }).notRequired(),
    [Informe.name]: Yup.boolean().required(`${Informe.requiredErrorMsg}`).typeError("Veuillez sélectionner une valeur valide"),
    [NomSecondaire.name]: Yup.string().when([Informe.name],
      {
        is: (Informe) => Informe !== null && Informe,
        then: Yup.string().required(`${NomSecondaire.requiredErrorMsg}`),
        otherwise: Yup.string().notRequired()
      }),
    [PrenomSecondaire.name]: Yup.string().when([Informe.name],
      {
        is: (Informe) => Informe !== null && Informe,
        then: Yup.string().required(`${PrenomSecondaire.requiredErrorMsg}`),
        otherwise: Yup.string().notRequired()
      }),
    [MailSecondaire.name]: Yup.string().email(`${MailSecondaire.invalidErrorMsg}`).when([Informe.name],
      {
        is: (Informe) => Informe !== null && Informe,
        then: Yup.string().required(`${MailSecondaire.requiredErrorMsg}`),
        otherwise: Yup.string().notRequired()
      }),
    [TelephoneSecondaire.name]: Yup.string().matches(/(^(0|\+33|0033)[1-9][0-9]{8}$)|(^(\+41|0041)[0-9]{9}$)|(^(\+49|0049)[0-9]{9,11}$)/, {
        message: `${TelephoneSecondaire.invalidErrorMsg}`,
      }).notRequired(),
    [RGPD.name]: Yup.bool().oneOf([true], RGPD.requiredErrorMsg).typeError("Veuillez sélectionner une valeur valide"),
  }),
  Yup.object().shape({})
];
