import checkoutFormModel from './checkoutFormModel';

const {
    formField: {
        AccordDonnees,
        ProblemeNonReseau,
        ProblemeNonFai,
        Geoloc,
        X,
        Y,
        EstLogementProche,
        NumeroVoie,
        LibelleVoie,
        ComplementAdresse,
        CodePostal,
        Ville,
        ReferenceImb,
        TypeMalfacon,
        Tiers,
        DemarcheEnCours,
        ReferenceInterne,
        CommentaireInterne,
        DateInstallation,
        PresenceInstallation,
        ReferencePTO,
        EstOccupant,
        EstProprietaire,
        AccordProprietaire,
        PiecesJointes,
        PiecesJointesSupplementaires,
        Commentaire,
        StatutContact,
        Nom,
        Prenom,
        Mail,
        Telephone,
        Informe,
        NomSecondaire,
        PrenomSecondaire,
        MailSecondaire,
        TelephoneSecondaire,
        Captcha,
        RGPD
    }
} = checkoutFormModel;

export default {
    [AccordDonnees.name]: false,
    [ProblemeNonReseau.name]: false,
    [ProblemeNonFai.name]: false,
    [Geoloc.name]: false,
    [X.name]: '',
    [Y.name]: '',
    [EstLogementProche.name]: null,
    [NumeroVoie.name]: '',
    [LibelleVoie.name]: '',
    [ComplementAdresse.name]: '',
    [CodePostal.name]: '',
    [Ville.name]: '',
    [ReferenceImb.name]: '',
    [TypeMalfacon.name]: '',
    [Tiers.name]: '',
    [DemarcheEnCours.name]: false,
    [ReferenceInterne.name]: '',
    [CommentaireInterne.name]: '',
    [DateInstallation.name]: null,
    [PresenceInstallation.name]: '',
    [ReferencePTO.name]: '',
    [EstOccupant.name]: '',
    [EstProprietaire.name]: '',
    [AccordProprietaire.name]: '',
    [PiecesJointes.name]: [],
    [PiecesJointesSupplementaires.name]: [],
    [Commentaire.name]: '',
    [StatutContact.name]: '',
    [Nom.name]: '',
    [Prenom.name]: '',
    [Mail.name]: '',
    [Telephone.name]: '',
    [Informe.name]: false,
    [NomSecondaire.name]: '',
    [PrenomSecondaire.name]: '',
    [MailSecondaire.name]: '',
    [TelephoneSecondaire.name]: '',
    [Captcha.name]:'',
    [RGPD.name]: false,
};
