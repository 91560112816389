import {React, useEffect, useState} from 'react';
import '../../App.css';
import {Grid, Typography,} from '@material-ui/core';
import {Field, useFormikContext} from "formik";
import {CheckBox, Input, MSelect, Switch} from "../../components/FormFields";
import {Constantes} from "../../services";

const Coordonnees = (props) => {
    const {values, submitForm} = useFormikContext();
    const [renderAgain, setRenderAgain] = useState(0);
    const [estProprietaire, setEstProprietaire] = useState(null);
    const [estOccupant, setEstOccupant] = useState(null);
    const [estInforme, setEstInforme] = useState(false);
    const {
        formField: {
            StatutContact,
            Nom,
            Prenom,
            Mail,
            Telephone,
            Informe,
            NomSecondaire,
            PrenomSecondaire,
            MailSecondaire,
            TelephoneSecondaire,
            EstOccupant,
            EstProprietaire,
            AccordProprietaire,
            RGPD,
        }
    } = props;

    useEffect(() => {
        if(values.estProprietaire !== '') {
            setEstProprietaire(Boolean(values.estProprietaire.value));
        }
        if(values.estOccupant !== '') {
            setEstOccupant(Boolean(values.estOccupant.value));
        }
        setEstInforme(values.informe);
    }, []);

    return (
        <>
            <Grid container spacing={1} justify="space-around">
                <Grid item xs={12}>
                    <Typography className="text-asterix" component="h4" variant="h6">
                        Les champs notés d'un * sont obligatoires.
                        <br/>
                        En déclarant une malfaçon, vous acceptez d'être recontacté afin qu'un technicien réintervienne sur le lieu
                        de la malfaçon afin de réparer les dommages causés.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} justify="space-around">
                <Grid item xs={12}>
                    <Typography className="page-title" component="h4" variant="h6">
                        Coordonnées
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={1} justify="flex-start" alignItems="flex-start" xs={12}>
                        <Grid className="input" item xs={12}>
                            <label className="form-control-label" htmlFor={EstOccupant.name}>{ EstOccupant.label }</label>
                            <Field
                              component={MSelect}
                              isFormik
                              name={EstOccupant.name}
                              optionLabel="label"
                              optionValue="value"
                              onChange={(e) => {
                                  setEstOccupant(Boolean(e.value));
                              }}
                              options={[
                                  {
                                      value: 0,
                                      label: Constantes.EstOccupant_NON
                                  },
                                  {
                                      value: 1,
                                      label: Constantes.EstOccupant_OUI
                                  }
                              ]}
                            />
                        </Grid>
                        <Grid className="input" item xs={12}>
                            <label className="form-control-label" htmlFor={EstProprietaire.name}>{ EstProprietaire.label }</label>
                            <Field
                              component={MSelect}
                              isFormik
                              name={EstProprietaire.name}
                              optionLabel="label"
                              optionValue="value"
                              onChange={(e) => {
                                  setEstProprietaire(Boolean(e.value));
                              }}
                              options={[
                                  {
                                      value: 0,
                                      label: Constantes.EstProprietaire_NON
                                  },
                                  {
                                      value: 1,
                                      label: Constantes.EstProprietaire_OUI
                                  }
                              ]}
                            />
                        </Grid>
                        {estProprietaire !== null && !estProprietaire && (
                          <Grid className="input" item xs={12}>
                              <label className="form-control-label" htmlFor={AccordProprietaire.name}>{ AccordProprietaire.label }</label>
                              <Field
                                component={MSelect}
                                isFormik
                                name={AccordProprietaire.name}
                                optionLabel="label"
                                optionValue="value"
                                options={[
                                    {
                                        value: '1',
                                        label: 'Oui'
                                    },
                                    {
                                        value: '0',
                                        label: 'Non'
                                    },
                                    {
                                        value: null,
                                        label: 'Ne sait pas'
                                    },
                                ]}
                              />
                          </Grid>
                        )}
                        { estOccupant !== null && estProprietaire !== null && !estOccupant && !estProprietaire && (
                          <Grid className="input" item xs={12}>
                              <label className="form-control-label" htmlFor={StatutContact.name}>{ StatutContact.label }</label>
                              <Field
                                component={MSelect}
                                isFormik
                                name={StatutContact.name}
                                optionLabel="label"
                                optionValue="value"
                                options={[
                                    {
                                        value: Constantes.StatutContact_COLLECTIVITELOCALE,
                                        label: Constantes.StatutContact_COLLECTIVITELOCALE,
                                    },
                                    {
                                        value: Constantes.StatutContact_PERSONNETIERCE,
                                        label: Constantes.StatutContact_PERSONNETIERCE,
                                    },
                                ]}
                              />
                          </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={1} justify="space-around" item xs={12}>
                        <Grid className="input" item xs={12}>
                            <Typography>
                                Pour la remise en conformité de votre raccordement,
                                merci de nous transmettre les coordonnées de la personne à informer de la suite du traitement,
                                et pour un éventuel RDV :
                            </Typography>
                        </Grid>
                        <Grid className="input" item xs={12} md={6}>
                            <label className="form-control-label" htmlFor={Nom.name}>{ Nom.label }</label>
                            <Field
                              component={Input}
                              isFormik
                              name={Nom.name}
                              fullWidth
                              variant="outlined"
                              style={{margin: 'auto'}}
                              margin="normal"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                            />
                        </Grid>
                        <Grid className="input" item xs={12} md={6}>
                            <label className="form-control-label" htmlFor={Prenom.name}>{ Prenom.label }</label>
                            <Field
                              component={Input}
                              isFormik
                              name={Prenom.name}
                              fullWidth
                              variant="outlined"
                              style={{margin: 'auto'}}
                              margin="normal"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                            />
                        </Grid>
                        <Grid className="input" item xs={12} md={6}>
                            <label className="form-control-label" htmlFor={Mail.name}>{ Mail.label }</label>
                            <Field
                              component={Input}
                              isFormik
                              name={Mail.name}
                              fullWidth
                              variant="outlined"
                              style={{margin: 'auto'}}
                              margin="normal"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                            />
                        </Grid>
                        <Grid className="input" item xs={12} md={6}>
                            <label className="form-control-label" htmlFor={Telephone.name}>{ Telephone.label }</label>
                            <Field
                              component={Input}
                              isFormik
                              name={Telephone.name}
                              fullWidth
                              variant="outlined"
                              style={{margin: 'auto'}}
                              margin="normal"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                            />
                        </Grid>
                        { (estOccupant !== null && estProprietaire !== null) && ((!estProprietaire) || (estProprietaire && !estOccupant) ) && (
                          <>
                              <Grid className="input" item xs={12}>
                                  <label className="form-control-label" htmlFor={Informe.name}>{ Informe.label }</label>
                                  <Field
                                    component={Switch}
                                    name={Informe.name}
                                    color="primary"
                                    choices={['Non', 'Oui']}
                                    onChange={(e, a) => {
                                        setEstInforme(a.target.checked);
                                    }}
                                    isFormik
                                  />
                              </Grid>
                              {estInforme && (
                                <>
                                    <Grid className="input" item xs={6}>
                                        <label className="form-control-label" htmlFor={NomSecondaire.name}>{ NomSecondaire.label }</label>
                                        <Field
                                          component={Input}
                                          isFormik
                                          name={NomSecondaire.name}
                                          fullWidth
                                          variant="outlined"
                                          style={{margin: 'auto'}}
                                          margin="normal"
                                          InputLabelProps={{
                                              shrink: true,
                                          }}
                                        />
                                    </Grid>
                                    <Grid className="input" item xs={6}>
                                        <label className="form-control-label" htmlFor={PrenomSecondaire.name}>{ PrenomSecondaire.label }</label>
                                        <Field
                                          component={Input}
                                          isFormik
                                          name={PrenomSecondaire.name}
                                          fullWidth
                                          variant="outlined"
                                          style={{margin: 'auto'}}
                                          margin="normal"
                                          InputLabelProps={{
                                              shrink: true,
                                          }}
                                        />
                                    </Grid>
                                    <Grid className="input" item xs={6}>
                                        <label className="form-control-label" htmlFor={MailSecondaire.name}>{ MailSecondaire.label }</label>
                                        <Field
                                          component={Input}
                                          isFormik
                                          name={MailSecondaire.name}
                                          fullWidth
                                          variant="outlined"
                                          style={{margin: 'auto'}}
                                          margin="normal"
                                          InputLabelProps={{
                                              shrink: true,
                                          }}
                                        />
                                    </Grid>
                                    <Grid className="input" item xs={6}>
                                        <label className="form-control-label" htmlFor={TelephoneSecondaire.name}>{ TelephoneSecondaire.label }</label>
                                        <Field
                                          component={Input}
                                          isFormik
                                          name={TelephoneSecondaire.name}
                                          fullWidth
                                          variant="outlined"
                                          style={{margin: 'auto'}}
                                          margin="normal"
                                          InputLabelProps={{
                                              shrink: true,
                                          }}
                                        />
                                    </Grid>
                                </>
                              )}
                          </>
                        )}
                    </Grid>
                </Grid>
                <Grid className="input" item xs={12}>
                    <label htmlFor={RGPD.name}>En cochant cette case, j’accepte que mes informations soient enregistrées et utilisées, uniquement pour la finalité de ce formulaire. Vous pouvez retrouver tous les détails sur cette finalité et vos droits en cliquant <a href='https://rgpd.altitudeinfra.fr/#traitements'>ici</a>.</label>
                    <Field
                      component={CheckBox}
                      isFormik
                      labelPlacement={'start'}
                      name={RGPD.name}
                      fullWidth
                      variant="outlined"
                      style={{margin: 'auto'}}
                      margin="normal"
                      InputLabelProps={{
                          shrink: true,
                      }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Coordonnees;
