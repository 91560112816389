import {Tooltip} from '@material-ui/core';
import React from 'react';

import './Breadcrumb.scss';

const Breadcrumb = ({ menu, value, ...props }) => {
  return (
    <nav className="navbar-breadcrumb">
      <ol className="cd-breadcrumb triangle custom-icons">
        {menu && menu.length > 0 && menu.map((item, index) => (
          <li className={value === item.id ? 'current' : ''} key={index}>
            {item.id === value ? (
              <Tooltip title={item.tooltip || ''} placement="bottom">
                <em style={{height:'25px'}}>{item.libelle}</em>
              </Tooltip>
            ) : (
              <Tooltip title={item.tooltip || ''} placement="bottom">
                <em style={{height:'25px'}}>{item.libelle}</em>
              </Tooltip>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
