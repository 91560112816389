import axios from '../config/axios';

const GET_TYPES_MALFACONS = 'api/irris/get-types-malfacon';
const GET_TIERS_OF_MARCHE = 'api/irris/get-tiers-of-marches';
const POST_CREATE_TICKET = 'api/irris/create-trouble-ticket-malfacon';

export const getTypesMalfacon = () => {
    return axios.get(GET_TYPES_MALFACONS, {});
};

export const getTiersByMarche = (codeMarche) => {
    return axios.get(GET_TIERS_OF_MARCHE, {
        params: {
            marches: [codeMarche],
        }
    });
};

export const postCreateTicket = (ticket, fichiers, idMalfacon) => {
    const formData = new FormData();
    formData.append('ticket', JSON.stringify(ticket));
    for (let i = 0; i < fichiers.piecesJointes.length; i++) {
        formData.append('fichiers['+idMalfacon+']', fichiers.piecesJointes[i]);
    }
    for (let i = 0; i < fichiers.piecesJointesSupplementaires.length; i++) {
        formData.append('piecesJointesSupplementaires[]', fichiers.piecesJointesSupplementaires[i]);
    }
    return axios.post(POST_CREATE_TICKET, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
