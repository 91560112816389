import React from 'react';
import propTypes from 'prop-types';
import './FileUpload.scss';

function FileUpload(props) {
  // const { field, form } = props;
  const { field, form: { touched: toucheds, errors, setFieldValue },
    isFormik, error, touched } = props;

  const handleChange = (e) => {
    const file = e.currentTarget.files[0];
    const reader = new FileReader();
    const imgTag = document.getElementById('myimage');
    imgTag.title = file.name;
    reader.onload = function (event) {
      imgTag.src = event.target.result;
    };
    reader.readAsDataURL(file);
    setFieldValue(field.name, file);
  };

  return (
    <div>
      <input type="file" onChange={o => handleChange(o)} className="form-control" />
      <img src="" alt="" id="myimage" className="image-PieceJointe" />
      {
      isFormik ?
        errors[field.name] && toucheds[field.name] && <div className="form-control-errorLabel">{errors[field.name]}</div> :
        error && touched && <div className="form-control-errorLabel">{error}</div>
      }
    </div>
  );
}

FileUpload.propTypes = {
  // global use
  // case Formik's select
  field: propTypes.any,
  form: propTypes.any,
  isFormik: propTypes.bool,
  // case not Formik's select
  name: propTypes.string,
};

FileUpload.defaultProps = {
  // case Formik's select
  field: null,
  form: null,
  isFormik: false,
  // case not Formik's select
  name: '',
};

export default FileUpload;
