import {React, useEffect, useState} from 'react';
import '../../App.css';
import {CardMedia, Grid, Typography} from '@material-ui/core';
import pto from '../../ressources/img/photo_pto-min.jpg';
import {ErrorMessage, Field, useFormikContext} from "formik";
import {tiersDefaultValue} from "../../services/constantes";
import {DatePicker, Input, MSelect, PieceJointe, Switch} from "../../components/FormFields";
import moment from 'moment';

const Description = (props) => {
    const {values, submitForm, setFieldValue} = useFormikContext();
    const [renderAgain, setRenderAgain] = useState(0);
    const [demarcheEnCours, setDemarcheEnCours] = useState(false);
    const [listTypesMalfacons, setListTypesMalfacons] = useState([]);
    const [listTiers, setListTiers] = useState([]);

    const {
        ptosList,
        formField: {
            TypeMalfacon,
            Tiers,
            DemarcheEnCours,
            ReferenceInterne,
            CommentaireInterne,
            DateInstallation,
            PresenceInstallation,
            ReferencePTO,
            PiecesJointes,
            PiecesJointesSupplementaires,
            Commentaire,
        }
    } = props;

    const confirmationVal = [
        {
            value: '1',
            label: 'Oui'
        },
        {
            value: '0',
            label: 'Non'
        },
    ];


    useEffect(() => {
        setListTypesMalfacons(props.typesMalfacon);
        setListTiers(props.tiers);
    }, []);

    return (
        <Grid container spacing={1} justify="space-around" xs={12}>
            <Grid container item spacing={1} justify="space-around">
                <Grid item xs={12}>
                    <Typography className="text-asterix" component="h4" variant="h6">
                        Les champs notés d'un * sont obligatoires.
                        <br/>
                        En déclarant une malfaçon, vous acceptez d'être recontacté afin qu'un technicien réintervienne sur le lieu
                        de la malfaçon afin de réparer les dommages causés.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className="page-title" component="h4" variant="h6">
                        Description de la malfaçon
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container item spacing={1} justify="space-around" xs={12}>
                    <Grid className="input" item xs={12}>
                        <label className="form-control-label" htmlFor={Tiers.name}>{ Tiers.label }</label>
                        <Field
                          component={MSelect}
                          isFormik
                          name={Tiers.name}
                          optionLabel="libelle"
                          optionValue="id"
                          options={listTiers}
                          defaultValue={tiersDefaultValue}
                        />
                    </Grid>
                    <Grid className="input" item xs={12}>
                        <label className="form-control-label" htmlFor={TypeMalfacon.name}>
                            { TypeMalfacon.label }
                            <span className="label-helper">
                                Si vous voulez sélectionner plusieurs types de malfaçon, merci de remplir un formulaire
                                par type.
                            </span>
                        </label>
                        <Field
                          component={MSelect}
                          isFormik
                          name={TypeMalfacon.name}
                          optionLabel="libelle"
                          optionValue="id"
                          options={listTypesMalfacons}
                        />
                    </Grid>
                    <Grid className="input" item xs={12}>
                        <label className="form-control-label" htmlFor={ReferencePTO.name}>{ ReferencePTO.label }</label>
                        <Field
                          component={MSelect}
                          isFormik
                          name={ReferencePTO.name}
                          optionLabel="referencePTO"
                          optionValue="referencePTO"
                          options={ptosList}
                          onChange={(infoPto) => {
                            setFieldValue(
                                'dateInstallation',
                                moment(infoPto.dateRaccoPTO, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')
                          )}}
                        />
                    </Grid>
                    <Grid className="input" item xs={8}>
                        <Typography className="text-helper">
                            La PTO est la Prise Terminale Optique, il s’agit généralement d’un boitier blanc dans lequel
                            arrive la fibre optique. La box internet de votre opérateur se branche sur ce boitier.
                            Normalement, une étiquette, avec la référence de votre PTO est collée sur ce boitier, cette
                            référence est sous le format « DB-xxxx-xxxx ».
                        </Typography>
                    </Grid>
                    <Grid className="input" item xs={4}>
                        <CardMedia className="img" component={'img'} image={pto}/>
                    </Grid>
                    <Grid className="input" item xs={12}>
                        <label className="form-control-label" htmlFor={PiecesJointes.name}>{ PiecesJointes.label }</label>
                        <PieceJointe
                          acceptedMimeType={"image/*"}
                          multipleFileSelect={false}
                          maxFiles={1}
                          initialFiles={values.piecesJointes}
                          onChange={(files) => {
                              values.piecesJointes = files;
                          }}
                          inputContent={'Déposer la photo de votre malfaçon'}
                        />
                        <ErrorMessage name={PiecesJointes.name}  render={msg => <div className="form-control-errorLabel">{msg}</div>}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container item spacing={1} justify="space-around" xs={12}>
                <Grid className="input" item xs={12}>
                        <label className="form-control-label" htmlFor={DateInstallation.name}>{ DateInstallation.label }</label>
                        <Field
                          component={DatePicker}
                          disableRange={true}
                          isFormik
                          disableFuture
                          name={DateInstallation.name}
                          fullWidth
                          variant="outlined"
                          disabled={!values.referencePTO}
                          style={{margin: 'auto', backgroundColor: '#ffffff', borderRadius: '4px'}}
                          margin="normal"
                          InputLabelProps={{
                              shrink: true,
                          }}
                        />
                        <Typography className="text-helper">
                            Lorsque l’information est disponible, nous vous proposons une date, qu’il convient de
                            vérifier. Si ce n’est pas le cas, merci de renseigner le champ.
                        </Typography>
                    </Grid>
                    <Grid className="input" item xs={12}>
                        <label className="form-control-label" htmlFor={DemarcheEnCours.name}>{ DemarcheEnCours.label }</label>
                        <Field
                          component={Switch}
                          name={DemarcheEnCours.name}
                          color="primary"
                          choices={['Non', 'Oui']}
                          onChange={(name, e) => {
                              setDemarcheEnCours(e.target.checked);
                          }}
                          isFormik
                        />
                    </Grid>
                    {demarcheEnCours && (
                      <Grid container spacing={1} justify="space-around" xs={12}>

                          <Grid className="input" item xs={12} md={6}>
                              <label className="form-control-label" htmlFor={ReferenceInterne.name}>{ ReferenceInterne.label }</label>
                              <Field
                                component={Input}
                                isFormik
                                name={ReferenceInterne.name}
                                fullWidth
                                variant="outlined"
                                style={{margin: 'auto'}}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                              />
                          </Grid>
                          <Grid xs={6}/>
                          <Grid className="input" item xs={12} md={6}>
                              <label className="form-control-label" htmlFor={CommentaireInterne.name}>{ CommentaireInterne.label }</label>
                              <Field
                                component={Input}
                                isFormik
                                name={CommentaireInterne.name}
                                fullWidth
                                variant="outlined"
                                style={{margin: 'auto'}}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                              />
                          </Grid>
                          <Grid xs={6}/>
                      </Grid>
                    )}
                    <Grid className="input" item xs={12}>
                        <label className="form-control-label" htmlFor={PresenceInstallation.name}>{ PresenceInstallation.label }</label>
                        <Field
                          component={MSelect}
                          isFormik
                          name={PresenceInstallation.name}
                          optionLabel="label"
                          optionValue="value"
                          options={confirmationVal}
                        />
                    </Grid>
                    <Grid className="input" item xs={12}>
                        <label className="form-control-label" htmlFor={PiecesJointesSupplementaires.name}>{ PiecesJointesSupplementaires.label }</label>
                        <PieceJointe
                          acceptedMimeType={"image/*"}
                          multipleFileSelect={true}
                          initialFiles={values.piecesJointesSupplementaires}
                          onChange={(files) => {
                              values.piecesJointesSupplementaires = files;
                          }}
                          // inputContent={'Déposer la photo de votre malfaçon'}
                        />
                        <ErrorMessage name={PiecesJointesSupplementaires.name}  render={msg => <div className="form-control-errorLabel">{msg}</div>}/>
                    </Grid>

                </Grid>
            </Grid>

            <Grid className="input" item xs={12}>
                <label className="form-control-label" htmlFor={Commentaire.name}>{ Commentaire.label }</label>
                <Field
                  component={Input}
                  isFormik
                  name={Commentaire.name}
                  fullWidth
                  variant="outlined"
                  style={{margin: 'auto'}}
                  margin="normal"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  multiline
                />
            </Grid>
        </Grid>
    );
};

export default Description;
