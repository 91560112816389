import React from 'react';
import '../../App.css';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CheckBox} from "../../components/FormFields";
import {Field} from 'formik';
import CarteAllowedSro from "../../assets/images/carte-dioptic-allowed-sro.png";


const useStyles = makeStyles((theme) => ({
    mainTitle: {
        color: '#003B5E',
        textAlign: 'center',
        margin: '0.25em',
        fontSize: '3em',
        fontWeight: 'bold',
        "@media (max-width: 700px)": {
            fontSize: '2em',
        }
    },
    title: {
        textAlign: 'center',
        margin: '0.25em',
        fontSize: '1.25em',
        fontWeight: 'bold'
    },
    input: {
        padding: '1em !important'
    },
    form: {
        width: '80%',
        margin: 'auto',
        borderRadius: '1em',
        boxShadow: '1px 1px 12px #555'
    },
    textAccueil: {
        textAlign: 'center',
        fontSize: '1em',
    },
    textAsterix: {
        textAlign: 'center',
        margin: '1%',
        fontSize: '0.8em',
    },
    sectionTitle: {
        backgroundColor: '#EF9700',
        borderRadius: '1em 1em 0 0',
        color: 'white',
        width: '100%'
    },
    textDanger: {
        textAlign: 'justify',
        fontSize: '0.90em',
        fontWeight: 'bold',
        margin: '1em'
    },
    textBleu: {
        textAlign: 'center',
        margin: '2%',
        fontSize: '1em',
        fontWeight: 'bold',
        color: '#003B5E',
        whiteSpace: 'pre-line'
    },
    checkbox: {
      marginRight: '0px',
    },
    checkboxContainer: {
      textAlign: 'center',
    },
    allowedSro: {
      width: '100%',
    }
}));

const Danger = (props) => {
    const {
        formField: {
            AccordDonnees,
            ProblemeNonReseau,
            ProblemeNonFai,
        }
    } = props;

    const classes = useStyles();

    return (
      <>
          <Typography className={classes.mainTitle} component="h1">
              Déclaration de malfaçons sur un raccordement client
          </Typography>
        <Grid container direction="row"
              justifyContent="center"
              alignItems="center">
          <Grid item xs={1}/>
          <Grid item xs={10}>
            <Typography className={classes.textBleu} component="h4" variant="h6">
              Cette plateforme est ouverte dans le cadre d’un audit du réseau de fibre optique du Doubs dans les zones
              ouvertes commercialement <u>avant le 21 septembre 2021</u> inclus. Carte disponible <a href={CarteAllowedSro} target={'_blank'}>ici</a>.
            </Typography>
            <Typography className={classes.textBleu} component="h4" variant="h6">
              Suite au raccordement à la fibre optique, vous constatez qu’une ou plusieurs malfaçons ont été réalisées :
              perçage non-conforme, mauvais rebouchage, passage de câble non-conforme, dégradation des lieux, etc. Ce
              site permet de nous les signaler.
            </Typography>
            <Typography className={classes.textBleu} component="h4" variant="h6">
              En cas de malfaçons multiples, merci de remplir plusieurs fois ce formulaire.
              <br/>
              Vous pouvez vous référer à la checklist disponible au lien ci-dessous, afin d’auto-évaluer la conformité
              de votre raccordement.
              <br/>
              <a href={'https://www.dioptic-fibre.fr/wp-content/uploads/sites/19/2021/10/Plaquette-raccordement-Dioptic.pdf'} target={'_blank'}>
                Plaquette raccordement</a>
            </Typography>
            <Typography className={classes.textBleu} component="h4" variant="h6">
              Le délai de traitement dépendra de l’analyse de chaque situation.
            </Typography>
            <Typography className={classes.textAccueil} component="h4" variant="h6">
              ***   ***
            </Typography>
            <Grid container spacing={1} justify="center">
              <Grid item xs={12} className={classes.checkboxContainer}>
                <Field
                  component={CheckBox}
                  isFormik
                  name={ProblemeNonFai.name}
                  label={ProblemeNonFai.label}
                  className={classes.checkbox}
                  fullWidth
                  variant="outlined"
                  style={{margin: 'auto'}}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Typography className={classes.textAccueil} component="h4" variant="h6">
              Vous rencontrez une panne sur votre ligne personnelle ? Vous devez déclarer votre incident directement
              auprès de votre opérateur commercial (FAI), qui suivra sa résolution.
              <br/>
              ***   ***
            </Typography>
            <Grid container spacing={1} justify="center">
              <Grid item xs={12} className={classes.checkboxContainer}>
                <Field
                  component={CheckBox}
                  isFormik
                  name={ProblemeNonReseau.name}
                  label={ProblemeNonReseau.label}
                  className={classes.checkbox}
                  fullWidth
                  variant="outlined"
                  style={{margin: 'auto'}}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Typography className={classes.textAccueil} component="h4" variant="h6">
              Vous avez constaté un dommage visible depuis la voie publique sur les infrastructures du réseau de fibre
              optique : poteau, câble ou armoire détérioré(e) ? Merci de le déclarer sur le site suivant
              : <a href={'https://dommages-reseaux.altitudeinfra.fr/?DSP=Dioptic'} target="_blank">Cliquez ici</a>
              <br/>
              ***   ***
            </Typography>
            <Grid container spacing={1} justify="center">
              <Grid item>
                <Field
                  component={CheckBox}
                  isFormik
                  name={AccordDonnees.name}
                  className={classes.checkbox}
                  label={
                    <Typography className="text-asterix" component="h4" variant="h6">
                      Les champs notés d'un * sont obligatoires.
                      <br/>
                      En déclarant une malfaçon, vous acceptez d'être contacté pour l’intervention d’un technicien.
                      <br/>
                      Vos coordonnées ne seront pas utilisées à des fins commerciales, mais uniquement dans le cas où
                      nous aurions besoin d’intervenir afin de remettre en état votre raccordement à la fibre optique.
                    </Typography>
                  }
                  fullWidth
                  variant="outlined"
                  style={{margin: 'auto'}}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </>
    );
};

export default Danger;
