/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import {useDebouncedCallback} from 'use-debounce';
import _ from 'lodash';
import {createKeyValObject} from '../../../utils';

import '../Inputs.scss';

const MSelect = ({
  // global use
  optionLabel, optionValue, options, label, openMenuOnFocus, isMulti, optional, nbMaxResult, isOptionDisabled, defaultValue,
  // case Formik's select
  field, form: { touched: toucheds, errors, setFieldValue }, isFormik, isResetField, isResetOnUpdate,
  // case not Formik's select
  name, value, onChange, error, touched, onUpdate, allowSelectAll, loadingMessage, noOptionsMessage, closeMenuOnSelect, isClearable,
  placeHolder,
  ...props
}) => {

  const finalOptions = createKeyValObject(options, optionLabel, optionValue, optional);

  const finalValues = isFormik ?
    ((typeof (field.value) === 'number') ?
      finalOptions.find(oo => oo[optionValue] === field.value) :
      Array.isArray(field.value) ? (field.value.map(o => finalOptions.find(oo => oo[optionValue] === o[optionValue]))) : field.value)
    : value;

  const [optionsFilter, setOptionsFilter] = useState([]);

  const allOption = {
    [optionLabel]: 'Tous',
    [optionValue]: '*',
  };

  const setOptions = (inputVal = '') => {
    let filter = options.filter(o => o[optionLabel].toString().toLowerCase().indexOf(inputVal.toLowerCase()) > -1);
    // Remove option selected
    filter = filter.filter(object => !_.find(value, val => val.toString() === object.value.toString()));

    // setInputValue(inputVal);

    // If has nbMaxResult
    if (nbMaxResult > 0) {
      setOptionsFilter(filter.slice(0, nbMaxResult));
    } else {
      setOptionsFilter(filter);
    }
  };

  const setNewOptions = (inputOption) => {
    setOptionsFilter(inputOption);
  };

  const [onInputChange] = useDebouncedCallback((e) => {
    if (onUpdate) {
      onUpdate(e, setNewOptions);
    }
    setOptions(e);
  }, 500);


  useEffect(() => {
    if (isFormik && isResetField) {
      setFieldValue(field.name, '');
    }
  }, [options]);

  useEffect(() => {
    if (isFormik && onUpdate && isResetField) {
      setFieldValue(field.name, '');
    }
  }, [isResetOnUpdate]);

  return (
    <div>
      <label className="form-control-label" htmlFor={isFormik ? (field.name) : name}>{label}</label>
      <CreatableSelect
        {...field}
        {...props}
        isValidNewOption={() => false}
        openMenuOnFocus={openMenuOnFocus}
        className={classnames(isFormik ? (errors[field.name] && toucheds[field.name] && 'form-control-error') : (error && touched && 'form-control-error'))}
        options={allowSelectAll ? [allOption, ...options] : optionsFilter}
        value={finalValues}
        onChange={(e, actionMeta) => {
          if (allowSelectAll) {
            if (e !== null && e.length > 0 && e[e.length - 1][optionValue] === allOption[optionValue]) {
              onChange ? onChange(e, actionMeta) : null;
              setFieldValue ? setFieldValue(isFormik ? field.name : name, optionsFilter) : null;
            } else {
              onChange ? onChange(e, actionMeta) : null;
              setFieldValue ? setFieldValue(isFormik ? field.name : name, e) : null;
            }
          } else {
            onChange ? onChange(e, actionMeta) : null;
            setFieldValue ? setFieldValue(isFormik ? field.name : name, e) : null;
          }
        }}
        onInputChange={e => onInputChange(e)}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        placeholder={placeHolder}
        menuPortalTarget={document.body}
        getOptionLabel={o => o[optionLabel]}
        getOptionValue={o => o[optionValue]}
        isMulti={isMulti}
        isClearable={isMulti ? true : isClearable}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9999, maxHeight: 550 }),
        }}
        onMenuOpen={() => setOptions()}
        isOptionDisabled={isOptionDisabled}
        defaultValue={defaultValue}
        closeMenuOnSelect={closeMenuOnSelect}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'rgb(239, 151, 0)',
            primary: 'rgb(0, 59, 94)',
          },
        })}
        filterOption={() => true} // Ajouter pour le 'Tout selectionner'
      />
      {
        isFormik ?
          errors[field.name] && toucheds[field.name] && (
          <div
            className="form-control-errorLabel"
          >{errors[field.name][optionValue] ? errors[field.name][optionValue] : errors[field.name]}
          </div>
          ) :
          error && touched && <div className="form-control-errorLabel">{error}</div>
      }
    </div>
  );
};

MSelect.propTypes = {
  // global use
  optionLabel: propTypes.string,
  optionValue: propTypes.string,
  options: propTypes.array.isRequired,
  label: propTypes.string,
  openMenuOnFocus: propTypes.bool,
  isMulti: propTypes.bool,
  // case Formik's select
  field: propTypes.any,
  form: propTypes.shape({
    touched: propTypes.any,
    errors: propTypes.any,
    setFieldValue: propTypes.func,
  }),
  isFormik: propTypes.bool,
  // case not Formik's select
  name: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  error: propTypes.arrayOf(propTypes.string),
  touched: propTypes.arrayOf(propTypes.string),
  nbMaxResult: propTypes.number,
  allowSelectAll: propTypes.bool,
  allOption: propTypes.shape({}),
  noOptionsMessage: propTypes.func,
  loadingMessage: propTypes.func,
  isResetField: propTypes.bool,
  isResetOnUpdate: propTypes.bool,
  onUpdate: propTypes.func,
  isOptionDisabled: propTypes.func,
  defaultValue: propTypes.object,
  closeMenuOnSelect: propTypes.bool,
  isClearable: propTypes.bool,
};

MSelect.defaultProps = {
  optionLabel: 'label',
  optionValue: 'value',
  label: '',
  openMenuOnFocus: false,
  isMulti: false,
  // case Formik's select
  field: null,
  form: {},
  isFormik: false,
  // case not Formik's select
  name: '',
  value: '',
  onChange: null,
  error: null,
  touched: null,
  nbMaxResult: 0,
  allowSelectAll: false,
  allOption: {},
  noOptionsMessage: () => "Pas d'option",
  loadingMessage: () => 'Chargement...',
  isResetField: false,
  isResetOnUpdate: false,
  onUpdate: null,
  isOptionDisabled: () => {},
  defaultValue: null,
  closeMenuOnSelect: true,
  isClearable: false,
  placeHolder: "Selectionner...",
};

export default MSelect;
