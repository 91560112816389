import {React, useEffect, useMemo, useState} from 'react';
import '../../App.css';
import {CircularProgress, Grid,} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {GeoJSON, MapContainer, Marker, TileLayer, useMap, useMapEvents} from 'react-leaflet'
import {Field, useFormikContext} from "formik";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from '../../ressources/img/marker-icon.png';
import emp from '../../assets/images/DEPLOYE_HOUSE.png';
import personne2 from '../../ressources/img/personne2.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {useSnackbar} from 'notistack';
import {getImmeubleByWkt, getInfoImmeuble, getPtoFromImb} from "../../services/MUX/immeuble";
import {Switch} from "../../components/FormFields";
import moment from 'moment';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
});

let EmpIcon = L.icon({
    iconUrl: emp,
    iconSize: [20, 20],
    iconAnchor: [10,20],
    popupAnchor: [5, -10],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
});

let PersIcon = L.icon({
    iconUrl: personne2,
    iconSize: [25, 25],
    iconAnchor: [5, 10],
    popupAnchor: [5, -10],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
});

L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles((theme) => ({
    input: {
        // padding: '1em !important'
    },
    checkbox: {
        display: 'flex',
    },
    checkboxLabel: {
        // display: 'flex',
        flexGrow: '2'
    },
    map: {
        height: '100px'
    },
    text: {
        textAlign: 'justify',
        fontSize: '0.8em',
        marginBottom: '0.5em'
    },
}));

const Carte = (props) => {

    const classes = useStyles();
    const {values, submitForm, setFieldValue} = useFormikContext();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [newMarker, setNewMarker] = useState();
    const [mapCenter, setMapCenter] = useState([]);
    const [mapBounds, setMapBounds] = useState([[52.126615, -7.366685], [40.225118, 11.582297]]);
    const [zoom, setZoom] = useState();
    const [marche, setMarche] = useState()
    const [emplacementsProches, setEmplacementsProches] = useState([])
    // Partie EmplacementsAdresse
    const [selectedIndex, setSelectedIndex] = useState();
    const [positionUser, setPositionUser] = useState([]);
    const [initialZoom, setInitialZoom] = useState(false);
    const [loadFromPreviousImb, setLoadFromPreviousImb] = useState(false);
    const [showMarche, setShowMarche] = useState(true);


    useEffect(() => {
        handleMapZoom();
    }, []);

    useEffect(() => {
        if(values.referenceImb !== '') {
            setSelectedIndex(values.referenceImb);
        }
        if(values.X !== '' && values.Y !== '') {
            setMapCenter([values.Y, values.X]);
            setZoom(17);
            setInitialZoom(true);
            setLoadFromPreviousImb(true);
        }
    }, []);

    const handleMapZoom = () => {
        if(props.activeDsp !== null) {
            setMarche(props.activeDsp);
            findMarcheCentre(props.activeDsp);
        } else {
            setMarche([]);
            setMapCenter(['46.60611', '1.87528']);
            setZoom(5);
        }
    };

    const getPosition = (position) => {
        values.X = position.coords.longitude;
        values.Y = position.coords.latitude;
        setInitialZoom(false);
        setPositionUser([position.coords.latitude, position.coords.longitude]);
    };

    const findMarcheCentre = (marche) => {
        let midX;
        let midY;
        const bounds = getBoundsOfGeojson(marche);
        if (typeof (bounds.xMin) !== 'undefined' && typeof (bounds.xMax) !== 'undefined'
          && typeof (bounds.yMin) !== 'undefined' && typeof (bounds.yMax) !== 'undefined') {
            midX = (bounds.xMin + bounds.xMax) / 2;
            midY = (bounds.yMin + bounds.yMax) / 2;
            setMapBounds([[bounds.yMax, bounds.xMin], [bounds.yMin, bounds.xMax]]);
            setMapCenter([midY, midX]);
        }
    };

    const getBoundsOfGeojson = (data) => {
        const bounds = {};
        const {coordinates} = data.geometry;
        const idMarche = data.properties.codeMarche;
        if (coordinates.length === 1) {
            // It's only a single Polygon
            // For each individual coordinate in this feature's coordinates...
            for (let j = 0; j < coordinates[0].length; j += 1) {
                // Update the bounds recursively by comparing the current xMin/xMax and yMin/yMax with the current coordinate
                bounds.xMin = bounds.xMin < coordinates[0][j][0] ? bounds.xMin : coordinates[0][j][0];
                bounds.xMax = bounds.xMax > coordinates[0][j][0] ? bounds.xMax : coordinates[0][j][0];
                bounds.yMin = bounds.yMin < coordinates[0][j][1] ? bounds.yMin : coordinates[0][j][1];
                bounds.yMax = bounds.yMax > coordinates[0][j][1] ? bounds.yMax : coordinates[0][j][1];
            }
        } else if (idMarche === 48 || idMarche === 52) {
            for (let j = 0; j < coordinates.length; j += 1) {
                // For each individual coordinate in this coordinate set...
                for (let k = 0; k < coordinates[j].length; k += 1) {
                    // Update the bounds recursively by comparing the current xMin/xMax and yMin/yMax with the current coordinate
                    bounds.xMin = bounds.xMin < coordinates[j][k][0] ? bounds.xMin : coordinates[j][k][0];
                    bounds.xMax = bounds.xMax > coordinates[j][k][0] ? bounds.xMax : coordinates[j][k][0];
                    bounds.yMin = bounds.yMin < coordinates[j][k][1] ? bounds.yMin : coordinates[j][k][1];
                    bounds.yMax = bounds.yMax > coordinates[j][k][1] ? bounds.yMax : coordinates[j][k][1];
                }
            }
        } else {
            // It's a MultiPolygon
            // Loop through each coordinate set
            for (let j = 0; j < coordinates.length; j += 1) {
                // For each individual coordinate in this coordinate set...
                for (let k = 0; k < coordinates[j][0].length; k += 1) {
                    // Update the bounds recursively by comparing the current xMin/xMax and yMin/yMax with the current coordinate
                    bounds.xMin = bounds.xMin < coordinates[j][0][k][0] ? bounds.xMin : coordinates[j][0][k][0];
                    bounds.xMax = bounds.xMax > coordinates[j][0][k][0] ? bounds.xMax : coordinates[j][0][k][0];
                    bounds.yMin = bounds.yMin < coordinates[j][0][k][1] ? bounds.yMin : coordinates[j][0][k][1];
                    bounds.yMax = bounds.yMax > coordinates[j][0][k][1] ? bounds.yMax : coordinates[j][0][k][1];
                }
            }
        }
        // Returns an object that contains the bounds of this GeoJSON data.
        // The keys describe a box formed by the northwest (xMin, yMin) and southeast (xMax, yMax) coordinates.
        return bounds;
    };

    const getListeEmplacements = async (LATX1, LNGX1, LATX2, LNGX2) => {
        try {
          const immeublesResponse = await getImmeubleByWkt(
            LATX1,
            LNGX1,
            LATX2,
            LNGX2
          );
    
          setEmplacementsProches(
            immeublesResponse?.features
              ?.filter(
                (imb) =>
                  imb.properties.nbPto > 0 &&
                  imb.geometry !== null &&
                  imb.properties.codeIMB !== null
              )
              ?.map((imb) => ({
                refExploitation: imb.properties.codeIMB,
                latitude: imb.geometry.coordinates[1],
                longitude: imb.geometry.coordinates[0],
              })) ?? []
          );
        } catch (error) {
          enqueueSnackbar(
            "Une erreur est survenue lors du chargement des équipements proches, veuillez réessayer.",
            {
              variant: "error",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        }
      };

    const getActiveIcon = (item) => {
        if (item.refExploitation === selectedIndex) {
            return DefaultIcon;
        } else {
            return EmpIcon;
        }
    };

    function AfficherMarches() {
        const map = useMap();

        if(!initialZoom && positionUser.length === 0) {
            map.fitBounds(mapBounds);
            setInitialZoom(true);
        }

        return (
          <GeoJSON data={marche}/>
        )
    }

    function AfficherEmplacements() {
        const map = useMap();
        const [zoomActual, setZoomActual] = useState(null); // initial zoom level provided for MapContainer
        const mapEvents = useMapEvents({
            zoomend: () => {
                setZoomActual(mapEvents.getZoom());
            },
            moveend: () => {
                setZoomActual(mapEvents.getZoom());
            },
        });

        if((zoomActual !== null && zoomActual >= 16) || loadFromPreviousImb) {
            let southWest = map.getBounds().getSouthWest();
            let northEast = map.getBounds().getNorthEast();
            getListeEmplacements(northEast.lat, northEast.lng, southWest.lat, southWest.lng);
            setShowMarche(false);
            setLoadFromPreviousImb(false);
        } else if (zoomActual !== null && zoomActual < 16 && emplacementsProches.length !== 0) {
            setShowMarche(true);
            setEmplacementsProches([]);
        }

        return (
          <></>
        )
    }

    function AfficherMarkerPersonne() {
        const map = useMap();
        const lat = positionUser[0];
        const lng = positionUser[1];

        if(!initialZoom) {
            map.setView([lat,lng], 16);
            setInitialZoom(true);
        }

        return (
          <Marker key={`new-marker`}
                  position={positionUser}
                  icon={PersIcon}
          >
          </Marker>
        )
    }

    const childComponentMemo = useMemo(() => <MapContainer
      center={mapCenter}
      zoom={zoom}
      scrollWheelZoom={true}
      id="mapId"
      maxBoundsViscosity="1.0"
      maxBounds={mapBounds}
      minZoom="8"
      style={{height: '35em'}}>
        <TileLayer
          attribution='donn&eacute;es &copy; <a href="//osm.org/copyright">OpenStreetMap</a>/ODbL - rendu <a href="//openstreetmap.fr">OSM France</a>'
          url="//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {(positionUser.length !== 0) &&
          <AfficherMarkerPersonne/>
        }
        {emplacementsProches.map((item, index) => (
            <Marker key={`new-marker-` + index}
                    index={index}
                    icon={getActiveIcon(item)}
                    position={[item.latitude, item.longitude]}
                    eventHandlers={{
                        click: (e) => {
                            if (selectedIndex !== e.target.options.index) {
                                getInfoImmeuble(item.refExploitation).then((response) => {
                                    let infoImb = response.features[0].properties;
                                    if(infoImb.libelleMarche === props.activeDsp.properties.libelleMarche) {
                                        setSelectedIndex(item.refExploitation);
                                        setFieldValue('X',item.longitude);
                                        setFieldValue('Y',item.latitude);
                                        setFieldValue('referenceImb',item.refExploitation);
                                        setFieldValue('numeroVoie',infoImb.numero + infoImb.extension);
                                        setFieldValue('libelleVoie',infoImb.voie);
                                        setFieldValue('ville',infoImb.commune);
                                        setFieldValue('codePostal',infoImb.codePostal);
                                        setFieldValue('referencePTO', '');

                                        getPtoFromImb(item.refExploitation).then((responsePTO) => {
                                            props.setPtosList(responsePTO);
                                            if(responsePTO.length === 1) {
                                                const infoPto = responsePTO[0];
                                                setFieldValue(
                                                    'referencePTO',
                                                    infoPto.referencePTO
                                                );
                                                setFieldValue(
                                                    'dateInstallation',
                                                    moment(infoPto.dateRaccoPTO, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')
                                                );
                                            }
                                        });
                                    } else {
                                        enqueueSnackbar('Cette adresse ne fait pas partie du périmètre actuel de la plateforme.', {
                                            variant: 'error',
                                            autoHideDuration: 10000,
                                            anchorOrigin: {
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }
                                        });
                                    }
                                }).finally(() => props.renderFn());
                            }
                        },
                    }}
            >
            </Marker>
          ))
        }
        {showMarche && (
          <AfficherMarches/>
        )}
        <AfficherEmplacements/>
    </MapContainer>,[marche, emplacementsProches, positionUser, selectedIndex]);

    return (
      <>
          {(mapCenter && marche) ?
            <>
                <Grid className="input" item xs={12}>
                    <label className="form-control-label" htmlFor={props.Geoloc.name}>{ props.Geoloc.label }</label>
                    <Field
                      component={Switch}
                      name={props.Geoloc.name}
                      color="primary"
                      choices={['Non', 'Oui']}
                      isFormik
                      onChange={(name, e) => {
                          if (e.target.checked && navigator.geolocation) {
                              navigator.geolocation.getCurrentPosition(getPosition);
                          } else {
                              setInitialZoom(false);
                              setPositionUser([]);
                              handleMapZoom();
                          }
                      }}
                    />
                </Grid>

                <Grid className="input" item xs={12}>
                    {childComponentMemo}
                </Grid>
            </>
            :
            <Grid className="input" item xs={12}>
                <Grid container justify="center">
                    <CircularProgress color="secondary"/>
                </Grid>
            </Grid>}
      </>
    );
};

export default Carte;
