/* eslint-disable no-unused-expressions */
import React from 'react';
import propTypes from 'prop-types';
import {FormControl, FormGroup, FormLabel, RadioGroup as MRadioGroup} from '@material-ui/core';

const RadioGroup = ({
  // propriété du radioGroup
  name, label, children, onChange,
  // case Formik's select
  field, form: { touched: toucheds, errors, setFieldValue }, isFormik,
  ...props }) => (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        <MRadioGroup
          {...field}
          {...props}
          name={name}
          row
          onChange={(e) => {
            (isFormik) ? setFieldValue(field.name, e.target.value)
              : (onChange) ? onChange(e.target.value) : null;
          }}
        >
          {children}
        </MRadioGroup>
        {isFormik &&

          errors[field.name] && toucheds[field.name] && <div className="form-control-errorLabel">{errors[field.name]}</div>
        }
      </FormGroup>
    </FormControl>
);


RadioGroup.propTypes = {
  // global use
  label: propTypes.string.isRequired,
  children: propTypes.object.isRequired,
  // case Formik's select
  field: propTypes.any,
  form: propTypes.shape({
    touched: propTypes.any,
    errors: propTypes.any,
    setFieldValue: propTypes.func,
  }),
  isFormik: propTypes.bool,
  // case not Formik's select
  name: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
};

RadioGroup.defaultProps = {
  // case Formik's select
  field: null,
  form: {},
  isFormik: false,
  // case not Formik's select
  name: '',
  value: '',
  onChange: null,
};

export default RadioGroup;
