import React, {Component} from 'react';
import {Button} from '@material-ui/core';
import './FileInput.scss';

class FileInput extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
    };
    this.inputRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    const { value: oldValue } = this.props;

    if (value !== oldValue && value === null) {
      this.setState({ files: [] });
    }
  }

  onChange(e) {
    const { files } = e.target;
    const filesArr = Array.prototype.slice.call(files);
    this.setState({ files: filesArr });
  }

  render() {
    const { files } = this.state;
    const { onChange } = this.props;
    return (
      <div className="fileInput">
        <input type="file" ref={this.inputRef} onChange={(e) => { this.onChange(e); onChange(e); }} style={{ display: 'none' }} />
        <Button className="fileInput-button" onClick={() => this.inputRef.current.click()}>Selectionner un fichier</Button>
        <div className="fileInput-preview">{files[0] ? files[0].name : ' '}</div>
      </div>
    );
  }
}

export default FileInput;
