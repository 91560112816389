import {React, useState} from 'react';
import '../../App.css';
import {Grid, Typography,} from '@material-ui/core';
import {Field} from 'formik';
import Carte from "../Map";
import {Constantes} from "../../services";
import {Input, MSelect} from "../../components/FormFields";


const InfoDemande = (props) => {
    const [renderAgain, setRenderAgain] = useState(0);
    const [estLogementProche, setEstLogementProche] = useState(false);
    const {
        setPtosList,
        formField: {
            Geoloc,
            X,
            Y,
            EstLogementProche,
            NumeroVoie,
            LibelleVoie,
            ComplementAdresse,
            CodePostal,
            Ville,
            ReferenceImb,
        }
    } = props;

    const estLogementProcheVal = [
        {
            value: Constantes.EstLogementProche_MONLOGEMENT_VAL,
            label: Constantes.EstLogementProche_MONLOGEMENT_LABEL,
        },
        {
            value: Constantes.EstLogementProche_LOGEMENTPROCHE_VAL,
            label: Constantes.EstLogementProche_LOGEMENTPROCHE_LABEL,
        },
    ];

    const rerenderForm = () => {
        setRenderAgain(renderAgain + 1)
    };

    return (
        <Grid container spacing={2} justify="space-around">
            <Grid container spacing={1} justify="space-around" xs={12} md={6}>
                <Grid item xs={12}>
                    <Typography className="page-title" component="h4" variant="h6">
                        Étape 1 : Veuillez zoomer sur la carte pour sélectionner votre adresse, en cliquant dessus.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Carte activeDsp={props.activeDsp} renderFn={rerenderForm} Geoloc={Geoloc} setPtosList={setPtosList} />
                    <Typography className="text-helper">
                        Si votre adresse n’apparaît pas sur la carte, ou si aucun pictogramme « Maison » n’est présent
                        sur votre adresse, merci de sélectionner un adresse voisine, et de répondre « oui » à la
                        question n°2 : S'agit-il de votre adresse ou d’une adresse proche ? Vous pourrez ainsi modifier
                        ces informations (étape 2).
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} justify="space-around" xs={12} md={6}>
                <Grid item xs={12}>
                    <Typography className="page-title" component="h4" variant="h6">
                        Étape 2 : Vérifiez les informations pré-renseignées.
                    </Typography>
                </Grid>
                <Grid className="input" item xs={12}>
                    <label className="form-control-label" htmlFor={EstLogementProche.name}>{ EstLogementProche.label }</label>
                    <Field
                      component={MSelect}
                      isFormik
                      name={EstLogementProche.name}
                      optionLabel="label"
                      optionValue="value"
                      options={estLogementProcheVal}
                      onChange={(e) => {
                          setEstLogementProche(e.value === Constantes.EstLogementProche_LOGEMENTPROCHE_VAL)
                      }}
                    />
                </Grid>
                <Grid container spacing={1} justify="space-around" xs={12}>
                    <Grid className="input" item xs={12} md={3}>
                        <label className="form-control-label" htmlFor={NumeroVoie.name}>{ NumeroVoie.label }</label>
                        <Field
                          component={Input}
                          isFormik
                          name={NumeroVoie.name}
                          fullWidth
                          variant="outlined"
                          style={{margin: 'auto'}}
                          margin="normal"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          readonly={!estLogementProche}
                        />
                    </Grid>
                    <Grid className="input" item xs={12} md={9}>
                        <label className="form-control-label" htmlFor={LibelleVoie.name}>{ LibelleVoie.label }</label>
                        <Field
                          component={Input}
                          isFormik
                          name={LibelleVoie.name}
                          fullWidth
                          variant="outlined"
                          style={{margin: 'auto'}}
                          margin="normal"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          readonly={!estLogementProche}
                        />
                    </Grid>
                </Grid>
                <Grid className="input" item xs={12}>
                    <label className="form-control-label" htmlFor={ComplementAdresse.name}>{ ComplementAdresse.label }</label>
                    <Field
                      component={Input}
                      isFormik
                      name={ComplementAdresse.name}
                      fullWidth
                      variant="outlined"
                      style={{margin: 'auto'}}
                      margin="normal"
                      InputLabelProps={{
                          shrink: true,
                      }}
                      multiline
                    />
                </Grid>
                <Grid container spacing={1} justify="space-around" xs={12}>
                    <Grid className="input" item xs={12} md={3}>
                        <label className="form-control-label" htmlFor={CodePostal.name}>{ CodePostal.label }</label>
                        <Field
                          component={Input}
                          isFormik
                          name={CodePostal.name}
                          fullWidth
                          variant="outlined"
                          style={{margin: 'auto'}}
                          margin="normal"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          readonly={!estLogementProche}
                        />
                    </Grid>
                    <Grid className="input" item xs={12} md={9}>
                        <label className="form-control-label" htmlFor={Ville.name}>{ Ville.label }</label>
                        <Field
                          component={Input}
                          isFormik
                          name={Ville.name}
                          fullWidth
                          variant="outlined"
                          style={{margin: 'auto'}}
                          margin="normal"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          readonly={!estLogementProche}
                        />
                    </Grid>
                </Grid>
                <Grid className="input" item xs={12}>
                    <label className="form-control-label" htmlFor={ReferenceImb.name}>{ ReferenceImb.label }</label>
                    <Field
                      component={Input}
                      isFormik
                      name={ReferenceImb.name}
                      fullWidth
                      variant="outlined"
                      style={{margin: 'auto'}}
                      margin="normal"
                      readonly
                      InputLabelProps={{
                          shrink: true,
                      }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default InfoDemande;
