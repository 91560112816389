export const EstLogementProche_MONLOGEMENT_VAL = "0";
export const EstLogementProche_MONLOGEMENT_LABEL = "Il s'agit de mon adresse";
export const EstLogementProche_LOGEMENTPROCHE_VAL = "1";
export const EstLogementProche_LOGEMENTPROCHE_LABEL = "Il s'agit d'une adresse proche";

export const StatutContact_COLLECTIVITELOCALE = "Collectivité locale";
export const StatutContact_PERSONNETIERCE = "Personne tierce (parents, proches ...)";
export const EstOccupant_OUI = "Occupant à cette adresse";
export const EstOccupant_NON = "Non-occupant à cette adresse";
export const EstProprietaire_OUI = "Propriétaire";
export const EstProprietaire_NON = "Non propriétaire";

export const EXCLUDED_TIERS_OCEN = [
  14, // BOUYGUES TELECOM
  15, // ORANGE
  16, // SFR
  189 // FREE
];

export const CORRESPONDANCE_TIERS_AI_EXTERNE = [
  {
    idAI: 2306, // SCOREFIT
    libelleExt : "ORANGE"
  },
  {
    idAI: 225, // IFT
    libelleExt : "FREE"
  },
  {
    idAI: 2332, // SDFAST
    libelleExt : "BOUYGUES TELECOM"
  },
];

export const tiersDefaultValue = {
  id: null,
  libelle: 'NE SAIT PAS'
};