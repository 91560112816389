/* eslint-disable no-undef */
import React, {useState} from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import {MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import frLocale from 'date-fns/locale/fr';
import DateFnsUtils from '@date-io/date-fns';


class FrLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    // return format(date, 'HH:mm', { locale: this.locale });
    return date;
  }
}

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { main: '#176aa7' },
    secondary: { main: '#ffa723' },
  },
});

const FTimePicker = ({
  styleName, name, label, format, showTodayButton, value,
  onChange, minutesStep,
  // case Formik's select
  field, form: { touched: toucheds, errors, setFieldValue }, isFormik,
  error,

  ...props
}) => {
  // State
  //const [selectedDate, setSelectedDate] = useState(value);
  const [selectedDate, setSelectedDate] = useState(field.value ? field.value : value);




  // On date selected
  const handleDateSelected = (val) => {
    // Change value
    setSelectedDate(val);

    // Call on change
    onChange(moment(val).format());
  };

  return (
    <>
      <label className="form-control-label" htmlFor={name}>{ label }</label>
      <MuiThemeProvider theme={defaultMaterialTheme}>
        <MuiPickersUtilsProvider utils={FrLocalizedUtils} locale={frLocale}>
          <TimePicker
            {...field}
            {...props}
            className={`timepicker-theme-${styleName}`}
            value={selectedDate}
            onChange={(e) => { handleDateSelected(e); setFieldValue(field.name, e); }}
            ampm={false}
            minutesStep={minutesStep}
            cancelLabel="Annuler"
            showTodayButton={showTodayButton}
            todayLabel="Aujourd'hui"
          />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>

      {
        isFormik ?
          errors[field.name] && toucheds[field.name] && <div className="form-control-errorLabel">{errors[field.name]}</div> :
          error && touched && <div className="form-control-errorLabel">{error}</div>
      }
    </>
  );
};

FTimePicker.propTypes = {
  onChange: propTypes.func,
  styleName: propTypes.string,
  format: propTypes.string,
  label: propTypes.string,
  name: propTypes.string,
  showTodayButton: propTypes.bool,
  value: propTypes.instanceOf(Date),
  minutesStep: propTypes.number,
};

FTimePicker.defaultProps = {
  onChange: () => {},
  styleName: '',
  format: 'd/MM/yyyy HH:mm',
  label: '',
  name: '',
  showTodayButton: true,
  value: new Date(),
  minutesStep: 5,
};

export default FTimePicker;
