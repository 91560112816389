import {cryptageMUX} from './cryptageMUX';

const ADRESSE_MUX = 'https://mux.altitudeinfra.fr/api/';

export const getInfoMarche = (marche) => {
  let muxUrl = 'marcheftthactif_v1r1';
  if(marche !== null) {
    muxUrl += '?codeMarche='+marche;
  }

  return fetch(ADRESSE_MUX + cryptageMUX(muxUrl))
    .then(response => response.json());
}
