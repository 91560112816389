/* eslint-disable no-unused-expressions */
import React from 'react';
import propTypes from 'prop-types';
import {Checkbox as MCheckBox, FormControlLabel} from '@material-ui/core';

const CheckBox = ({
  name, label, checked, onChange, className, labelPlacement,
  // case Formik's select
  field, form: { touched: toucheds, errors, setFieldValue }, isFormik, error, touched,
  ...props
}) => (
  <>
    <FormControlLabel
      labelPlacement={labelPlacement}
      label={label}
      control={(
        <MCheckBox
          {...field}
          {...props}
          name={name}
          className={className}
          checked={isFormik ? (field.value) : checked}
          onChange={(e, actionMeta) => { isFormik ? setFieldValue(field.name, e.target.checked) : null; }}
          value={name}
        />
      )}
    />
    { isFormik ?
      errors[field.name] && toucheds[field.name] && <div className="form-control-errorLabel">{errors[field.name]}</div> :
      error && touched && <div className="form-control-errorLabel">{error}</div>}
  </>
);

CheckBox.propTypes = {
  className: propTypes.string,
  name: propTypes.string,
  checked: propTypes.bool,
  onChange: propTypes.func,
  // case Formik's select
  field: propTypes.any,
  form: propTypes.shape({
    touched: propTypes.any,
    errors: propTypes.any,
    setFieldValue: propTypes.func,
  }),
  isFormik: propTypes.bool,
};

CheckBox.defaultProps = {
  className: '',
  onChange: () => {},
  name: '',
  checked: null,
  field: null,
  form: {},
  isFormik: false,
};

export default CheckBox;
