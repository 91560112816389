import {cryptageMUX} from './cryptageMUX';

const ADRESSE_MUX = 'https://mux.altitudeinfra.fr/api/';

export const getImmeubleByWkt = (LATX1, LNGX1, LATX2, LNGX2) => fetch(ADRESSE_MUX + cryptageMUX(`spatial/irris_immeuble_v1r3?wkt={${LNGX1} ${LATX1};${LNGX2} ${LATX2}}`))
  .then(response => response.json());

export const getInfoImmeuble = (codeImb) => fetch(ADRESSE_MUX + cryptageMUX(`irris_immeuble_v1r3?refIMB=${codeImb}`))
  .then(response => response.json());

export const getPtoFromImb = (codeImb) => fetch(ADRESSE_MUX + cryptageMUX(`irris_pto_v1r3?refIMB=${codeImb}`))
  .then(response => response.json());
