export default {
    formId: 'checkoutForm',
    formField: {
        AccordDonnees: {
            name: 'AccordDonnees',
            label: 'accordDonnees',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        ProblemeNonFai: {
            name: 'problemeNonFai',
            label: 'Merci de confirmer que le problème ne concerne pas une panne sur votre ligne personnelle.',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        ProblemeNonReseau: {
            name: 'problemeNonReseau',
            label: 'Merci de confirmer que votre problème n\'est pas visible sur la voie publique.',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        Geoloc: {
            name: 'Geoloc',
            label: '1) J\'active la géolocalisation ?',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        X: {
            name: 'X',
            label: 'X',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        Y: {
            name: 'Y',
            label: 'Y',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        EstLogementProche: {
            name: 'estLogementProche',
            label: '2) S\'agit-il de votre adresse ou d\'une adresse proche ?',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        NumeroVoie: {
            name: 'numeroVoie',
            label: '3) N° de voie*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        LibelleVoie: {
            name: 'libelleVoie',
            label: '4) Nom de voie*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        ComplementAdresse: {
            name: 'complementAdresse',
            label: '5) Complément d\'adresse',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        CodePostal: {
            name: 'codePostal',
            label: '6) Code postal*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        Ville: {
            name: 'ville',
            label: '7) Ville*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        ReferenceImb: {
            name: 'referenceImb',
            label: 'IMB',
            requiredErrorMsg: 'Ce champ est obligatoire. Veuillez sélectionner un point sur la carte.'
        },
        Tiers: {
            name: 'tiers',
            label: '1) Quel opérateur a réalisé votre raccordement ?*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        TypeMalfacon: {
            name: 'typeMalfacon',
            label: '2) Quel type de malfaçon constatez-vous ?*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        ReferencePTO: {
            name: 'referencePTO',
            label: '3) Référence PTO*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        PiecesJointes: {
            name: 'piecesJointes',
            label: '4) Photo principale de la malfaçon*',
            requiredErrorMsg: 'Ce champ est obligatoire',
            lengthErrorMsg: 'Une seule pièce jointe possible'
        },
        DateInstallation: {
            name: 'dateInstallation',
            label: '5) Date de l\'installation',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        DemarcheEnCours: {
            name: 'demarcheEnCours',
            label: '6) Une démarche a-t-elle déjà été entamée auprès de votre opérateur afin de réparer la malfaçon ?',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        ReferenceInterne: {
            name: 'referenceInterne',
            label: 'Référence de dossier interne',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        CommentaireInterne: {
            name: 'commentaireInterne',
            label: 'Commentaires',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        PresenceInstallation: {
            name: 'presenceInstallation',
            label: '7) Étiez-vous personnellement présent le jour de l\'installation ?',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        PiecesJointesSupplementaires: {
            name: 'piecesJointesSupplementaires',
            label: '8) Pièce(s) jointe(s) complémentaire(s) si besoin',
            requiredErrorMsg: 'Ce champ est obligatoire',
            sameNameErrorMsg: 'Les fichiers importés ne peuvent avoir le même nom'
        },
        Commentaire: {
            name: 'commentaire',
            label: '9) Commentaires*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        EstOccupant: {
            name: 'estOccupant',
            label: '1) Êtes vous l\'occupant à cette adresse ?*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        EstProprietaire: {
            name: 'estProprietaire',
            label: '2) Êtes vous propriétaire à l\'adresse sélectionnée ?*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        AccordProprietaire: {
            name: 'accordProprietaire',
            label: 'Avez-vous eu l\'autorisation de votre propriétaire ?*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        StatutContact: {
            name: 'statutContact',
            label: 'Vous êtes : *',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        Nom: {
            name: 'nom',
            label: '3) Nom*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        Prenom: {
            name: 'prenom',
            label: '4) Prénom*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        Mail: {
            name: 'mail',
            label: '5) Adresse mail*',
            requiredErrorMsg: 'Ce champ est obligatoire',
            invalidErrorMsg: 'Adresse mail non valide'
        },
        Telephone: {
            name: 'telephone',
            label: '6) Téléphone',
            invalidErrorMsg: 'Numéro non valide'
        },
        Informe: {
            name: 'informe',
            label: '7) Souhaitez vous également être informé du traitement de cette demande ?*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        NomSecondaire: {
            name: 'nomSecondaire',
            label: 'Nom*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        PrenomSecondaire: {
            name: 'prenomSecondaire',
            label: 'Prénom*',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        MailSecondaire: {
            name: 'mailSecondaire',
            label: 'Adresse mail*',
            requiredErrorMsg: 'Ce champ est obligatoire',
            invalidErrorMsg: 'Adresse mail non valide'
        },
        TelephoneSecondaire: {
            name: 'telephoneSecondaire',
            label: 'Téléphone',
            invalidErrorMsg: 'Numéro non valide'
        },
        Captcha: {
            name: 'Captcha',
            label: 'Captcha',
            requiredErrorMsg: 'Ce champ est obligatoire'
        },
        RGPD: {
            name: 'rgpd',
            label: 'RGPD',
            requiredErrorMsg: 'Ce champ est obligatoire'
        }
    }
};
