/* eslint-disable no-unused-expressions */
import React from 'react';
import propTypes from 'prop-types';
import {FormControl, FormGroup, FormLabel, Grid, Switch as MSwitch} from '@material-ui/core';

import './Switch.scss';

const Switch = ({
  name, label, checked, onChange, choices, className,
  // case Formik's select
  field, form: { touched: toucheds, errors, setFieldValue }, isFormik,
  ...props
}) => (
  <FormControl component="fieldset">
    <FormLabel style={{ transform: 'scale(0.75) translate(-13px, 1.5px)' }} component="legend">{label}</FormLabel>
    <FormGroup>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>{choices[0]}</Grid>
        <Grid item>
          <MSwitch
            {...field}
            {...props}
            name={name}
            className={className}
            checked={isFormik ? (field.value) : checked}
            onChange={(e, actionMeta) => { onChange ? onChange(name, e) : null; isFormik ? setFieldValue(isFormik ? field.name : name, e.target.checked) : null; }}
            value={name}
          />
        </Grid>
        <Grid item>{choices[1]}</Grid>
      </Grid>
    </FormGroup>
  </FormControl>
);

Switch.propTypes = {
  className: propTypes.string,
  name: propTypes.string,
  checked: propTypes.bool,
  onChange: propTypes.func,
  choices: propTypes.arrayOf(propTypes.string),
  // case Formik's select
  field: propTypes.any,
  form: propTypes.shape({
    touched: propTypes.any,
    errors: propTypes.any,
    setFieldValue: propTypes.func,
  }),
  isFormik: propTypes.bool,
};

Switch.defaultProps = {
  choices: ['Non', 'Oui'],
  className: '',
  name: '',
  checked: null,
  field: null,
  form: {},
  isFormik: false,
  onChange: () => {},
};

export default Switch;
