import React, {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import {format} from 'date-fns';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

import './DatePicker.scss';

class FrLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'dd/MM/yyyy', { locale: this.locale });
  }
}

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { main: 'rgb(0, 59, 94) !important' },
    secondary: { main: 'rgb(239, 151, 0) !important' },
  },
  overrides: {
    MuiOutlinedInput: {
      // input: {
      //   padding: '9.5px 14px !important',
      // },
      root: {
        backgroundColor: '#ffffff',
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: '#ffffff !important',
      },
    },
  },
});

const FDatePicker = ({
  styleName, name, label, format, showTodayButton, value, size,
  invalidDateMessage, maxDateMessage, minDateMessage, maxDate, minDate,
  customPicker,
  onChange,
  // case Formik's select
  field, form: { touched: toucheds, errors, setFieldValue }, isFormik,
  error, touched,
  ...props
}) => {
// State
  const [selectedDate, setSelectedDate] = useState((isFormik) ? field.value : value);

  // On date selected
  const handleDateSelected = (value) => {
    // Change value
    setSelectedDate(value);

    // Call on change
    onChange(value && moment(value).isValid() ? moment(value).format() : moment(value));
  };

  useEffect(() => {
    setSelectedDate(field.value);
  }, [field.value]);

  useEffect(() => {
    if (!isFormik) {
      setSelectedDate(value);
    }
  }, [value]);


  return (
    <>
      <label className="form-control-label" htmlFor={name}>{ label }</label>
      <MuiThemeProvider theme={defaultMaterialTheme}>
        <MuiPickersUtilsProvider utils={FrLocalizedUtils} locale={frLocale}>
          <>
            { !customPicker ? (
              <DatePicker
                {...field}
                {...props}
                className={`datepicker-theme-${styleName}`}
                value={selectedDate}
                onChange={(e) => { handleDateSelected(e); isFormik && setFieldValue(field.name, e); }}
                format={format}
                cancelLabel="Annuler"
                showTodayButton={showTodayButton}
                todayLabel="Aujourd'hui"
                ampm={false}
                invalidDateMessage={invalidDateMessage}
                maxDateMessage={maxDateMessage}
                minDateMessage={minDateMessage}
                maxDate={maxDate}
                minDate={minDate}
                size={size}
              />
            ) : (
              <KeyboardDatePicker
                {...field}
                {...props}
                className={`datepicker-theme-${styleName}`}
                value={selectedDate}
                onChange={(e) => { handleDateSelected(e); isFormik && setFieldValue(field.name, e); }}
                format={format}
                inputVariant="outlined"
                cancelLabel="Annuler"
                showTodayButton={showTodayButton}
                todayLabel="Aujourd'hui"
                ampm={false}
                invalidDateMessage={invalidDateMessage}
                maxDateMessage={maxDateMessage}
                minDateMessage={minDateMessage}
                maxDate={maxDate}
                minDate={minDate}
                size={size}
              />
            ) }
            { isFormik ?
              errors[field.name] && toucheds[field.name] && <div className="form-control-errorLabel">{errors[field.name]}</div> :
              error && touched && <div className="form-control-errorLabel">{error}</div>
              }
          </>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </>
  );

};

FDatePicker.prototype = {
  onChange: propTypes.func,
  styleName: propTypes.string,
  format: propTypes.string,
  label: propTypes.string,
  name: propTypes.string,
  showTodayButton: propTypes.bool,
  value: propTypes.instanceOf(Date),
  customPicker: propTypes.bool,
  invalidDateMessage: propTypes.string,
  maxDateMessage: propTypes.string,
  minDateMessage: propTypes.string,
  maxDate: propTypes.instanceOf(Date),
  minDate: propTypes.instanceOf(Date),
  // case Formik's
  field: propTypes.any,
  form: propTypes.shape({
    touched: propTypes.any,
    errors: propTypes.any,
    setFieldValue: propTypes.any,
  }),
  isFormik: propTypes.bool,
  size: propTypes.string,
};

FDatePicker.defaultProps = {
  onChange: () => {},
  styleName: '',
  format: 'dd/MM/yyyy',
  label: '',
  name: '',
  showTodayButton: true,
  value: new Date(),
  customPicker: false,
  invalidDateMessage: 'Format de date non valide',
  maxDateMessage: 'La date ne doit pas être postérieure à la date maximale',
  minDateMessage: 'La date ne doit pas être antérieure à la date minimale',
  maxDate: new Date(2100, 1, 1),
  minDate: new Date(1900, 1, 1),
  // case Formik's
  field: {},
  form: {},
  isFormik: false,
  size: 'small',
};

export default FDatePicker;
