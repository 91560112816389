/* eslint-disable no-unused-expressions */
import React, {useEffect} from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import {CircularProgress, TextField} from '@material-ui/core';

import '../Inputs.scss';
import './Input.scss';

const Input = ({
  // global use
  step, type, readonly, label, multiline, rows, variant, size, loading,
  // case Formik's select
  field, form: { touched: toucheds, errors, setFieldValue }, isFormik, isResetOnUpdate,
  // case not Formik's select
  name, value, onChange, error, touched,
  ...props
}) => {

  useEffect(() => {
    if (isFormik && isResetOnUpdate) {
      setFieldValue(field.name, '');
    }
  }, [isResetOnUpdate]);

  return (
    <>
      <TextField
        {...field}
        {...props}
        label={label}
        variant={variant}
        className={classnames('form-control-input', isFormik ? (errors[field.name] && toucheds[field.name] && 'form-control-error') : (error && touched && 'form-control-error'))}
        type={type}
        step={step}
        onChange={(e, item) => {
          onChange ? onChange(e) : null;
          setFieldValue ? setFieldValue(isFormik ? field.name : name, e.target.value) : null;
        }}
        disabled={readonly}
        placeholder={label}
        multiline={multiline}
        rows={rows}
        size={size}
        InputProps={{
          endAdornment: (
              <>
                {loading ? (
                    <CircularProgress color="primary" size={20} />
                ) : null}
              </>
          ),
        }}
      />
      {isFormik ?
        errors[field.name] && toucheds[field.name] && <div className="form-control-errorLabel">{errors[field.name]}</div> :
        error && touched && <div className="form-control-errorLabel">{error}</div>}
    </>
  );
};

Input.propTypes = {
  // global use
  type: propTypes.string,
  readonly: propTypes.bool,
  step: propTypes.number,
  label: propTypes.string.isRequired,
  // case Formik's select
  field: propTypes.any,
  form: propTypes.shape({
    touched: propTypes.any,
    errors: propTypes.any,
    setFieldValue: propTypes.any,
  }),
  isFormik: propTypes.bool,
  // case not Formik's select
  name: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  error: propTypes.arrayOf(propTypes.string),
  touched: propTypes.arrayOf(propTypes.string),
  isResetOnUpdate: propTypes.bool,
  multiline: propTypes.bool,
  rows: propTypes.number,
  variant: propTypes.string,
  size: propTypes.string,
};

Input.defaultProps = {
  // global use
  type: 'input',
  readonly: false,
  step: 1,
  // case Formik's select
  field: {},
  form: {},
  isFormik: false,
  // case not Formik's select
  name: '',
  value: '',
  onChange: () => null,
  error: null,
  touched: null,
  isResetOnUpdate: null,
  multiline: false,
  rows: 4,
  variant: 'standard',
  size: 'small',
};

export default Input;
