import {useEffect, useState} from 'react';
import {format} from 'date-fns';

// format a timestamp into readable date
export function formatDate(dateToFormat, formatString) {
  const dt = new Date(dateToFormat);
  const dtOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  return format(dtOnly, formatString);
}

export function getFileNameExport(value) {
  return `${formatDate(new Date(), 'yyyyMMdd')}_Export_${value}`;
}

// Permet de retirer la référence qui lie un objet, et va réaliser une copie
export function clone(objet) {
  return JSON.parse(JSON.stringify(objet));
}

export function createKeyValObject(options, optionLabel, optionValue, optional) {
  const objects = [];
  if (optional) {
    options.map((o) => {
      const temp = {};
      temp[optionLabel] = o[optionLabel];
      temp[optionValue] = o[optionValue];
      optional.map((key) => temp[key] = o[key]);
      objects.push(temp);
    });
  } else {
    options.map((o) => (o instanceof Object ? (
      objects.push({
        [optionLabel]: o[optionLabel],
        [optionValue]: o[optionValue],
      })) : (
      objects.push({
        [optionLabel]: o,
        [optionValue]: o,
      }))));
  }

  return objects;
}

export function encodeURI(url) {
  let chemin = encodeURIComponent(url);
  chemin = chemin.replaceAll('%2F', '/');
  return chemin;
}

/**
 * Function permettant de retirer les majuscules
 * De retirer les accents et les apostrophes d'une chaine de caractère.
 * */
export function modificationCasse(string, maj, removeAccent, removeApostrophe) {
  let str = '';
  if (string !== '' && string !== undefined && string !== null) {
    str = string;
    if (maj) {
      str = str.toUpperCase();
    }
    if (removeAccent) {
      str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
    if (removeApostrophe) {
      str = str.replace(/'/g, ' ');
      str = str.replace('’', ' ');
      str = str.replace('-', ' ');
    }
  }
  return str;
}

export function convertMinuteToHour(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours}h${Math.trunc(minutes) > 9 ? Math.trunc(minutes) : `0${Math.trunc(minutes)}`}`;
}

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

function getWindowDimensions() {
  const {
    innerWidth: width,
    innerHeight: height,
  } = window;
  return {
    width,
    height,
  };
}
/**
 * Function permetttant de retourner la taille de l'écran lorsque celui-ci est redimensionné.
 * @returns Retourne les dimensions de l'écran width - height
 */
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
