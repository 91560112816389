import React, {useEffect, useState} from 'react';
import './App.css';
import {Backdrop, Button, CircularProgress, Fade, Grid, Typography,} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Form, Formik} from 'formik';
import Danger from "./views/Danger";
import InfoDemande from "./views/Adresse";
import Description from "./views/Description";
import Coordonnees from "./views/Coordonnees";

import validationSchema from './components/FormModel/validationSchema';
import checkoutFormModel from './components/FormModel/checkoutFormModel';
import formInitialValues from './components/FormModel/formInitialValues';
import {getTiersByMarche, getTypesMalfacon, postCreateTicket} from './ressources/services/irris'
import moment from 'moment'
import Breadcrumb from "./components/Breadcrumb";
import {getInfoMarche} from "./services/MUX/marche";
import Validation from "./views/Validation";
import Modal from "./components/Modal";
import {useSnackbar} from 'notistack';
import {CORRESPONDANCE_TIERS_AI_EXTERNE, EXCLUDED_TIERS_OCEN, tiersDefaultValue} from "./services/constantes";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f5f5f5',
        minHeight: '100vh',
        padding: theme.spacing(2)
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    title: {
        color: '#003B5E',
        textAlign: 'center',
        margin: '0.25em',
        fontSize: '3em',
        fontWeight: 'bold',
        "@media (max-width: 700px)": {
            fontSize: '2em',
        }
    },
    text: {
        textAlign: 'justify',
        margin: '1em',
        fontSize: '1em',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    input: {
        padding: '1em !important'
    },
    copyright: {
        color: "lightgray",
        fontSize: '0.75em',
    },
    altiIcon: {
        width: '30%',
        "@media (max-width: 700px)": {
            width: '50% !important'
        }
    },
    altiIconMin: {
        width: '40%'
    },
    iconGrid: {
        textAlign: 'center'
    },
    iconGridMin: {
        textAlign: 'right',
        marginLeft: '30%',
        fontSize: '0.8em'
    },
    buttonField: {
        margin: '2em 0 0 0',
        padding: '0.5em !important',
        width: '100%'
    },
    button: {
        backgroundColor: '#003B5E',
        color: 'white',
        fontWeight: 'bold'
    },
    form: {
        width: '100% !important'
    }
}));

const {formId, formField} = checkoutFormModel;
const steps = ['Danger', 'InfoDemande', 'Description', 'Coordonnees', 'Validation'];

function _renderStepContent(step, dsp, tiers, typesMalfacon, ptosList, setPtosList) {
    switch (step) {
        case 0:
            return <Danger formField={formField}/>;
        case 1:
            return <InfoDemande formField={formField} activeDsp={dsp} setPtosList={setPtosList} />;
        case 2:
            return <Description formField={formField} activeDsp={dsp} tiers={tiers} typesMalfacon={typesMalfacon} ptosList={ptosList} />;
        case 3:
            return <Coordonnees formField={formField}/>;
        case 4:
            return <Validation formField={formField}/>;
        default:
            return <div>Not Found</div>;
    }
}

const stepMenu = [
    {
        id: 1,
        libelle: 'Information sur votre adresse',
    },
    {
        id: 2,
        libelle: 'Description de la malfaçon',
    },
    {
        id: 3,
        libelle: 'Coordonnées',
    },
    {
        id: 4,
        libelle: 'Confirmation',
    },
];

const App = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [numeroTicket, setNumeroTicket] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [activeDsp, setActiveDsp] = useState(null);
    const {enqueueSnackbar} = useSnackbar();

    const [listTypesMalfacons, setListTypesMalfacons] = useState([]);
    const [listTiers, setListTiers] = useState([]);
    const [ptosList, setPtosList] = useState([]);

    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;

    useEffect(() => {
        const urlParam = window.location.search;
        const param = new URLSearchParams(urlParam);
        // const dsp = param.get('DSP');
        const dsp = 'MEN00';

        getInfoMarche(dsp)
          .then((response) => {
              if(response.features.length === 1) {
                  setActiveDsp(response.features[0]);
              } else {
                  setActiveDsp(null);
              }
          });

    }, []);

    useEffect(() => {
        if(activeDsp !== null) {
            setLoading(true);
            Promise.all([
                getTypesMalfacon().then(response => {
                    let listTypesMalfaconsTemp = [];
                    response.data.forEach((typeMalfacon) => {
                        listTypesMalfaconsTemp.push(typeMalfacon);
                    });
                    setListTypesMalfacons(listTypesMalfaconsTemp);
                }).catch(function (error) {
                    enqueueSnackbar('Impossible de récupérer la liste des malfaçons', {
                        variant: 'error',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }),
                getTiersByMarche(activeDsp.properties.codeMarche).then(response => {
                    let listTiersTemp = [];
                    response.data
                      .filter(tiers => (tiers.isOcen && !EXCLUDED_TIERS_OCEN.includes(tiers.id)) || tiers.libelle === 'IFT')
                      .forEach((tiers) => {
                          let filteredTiers = CORRESPONDANCE_TIERS_AI_EXTERNE.filter(tierstemp => tierstemp.idAI === tiers.id);
                          if(filteredTiers.length === 1) {
                              tiers.libelle = filteredTiers[0].libelleExt;
                          }
                          listTiersTemp.push(tiers);
                      });

                    listTiersTemp.sort((a,b) => (a.libelle > b.libelle) ? 1 : ((b.libelle > a.libelle) ? -1 : 0));

                    listTiersTemp.push({
                        id: null,
                        libelle: 'AUTRE'
                    });
                    listTiersTemp.push(tiersDefaultValue);
                    setListTiers(listTiersTemp);
                }).catch(function (error) {
                    enqueueSnackbar('Impossible de récupérer la liste de tiers', {
                        variant: 'error',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }),
            ]).finally(() => setLoading(false));
        }
    }, [activeDsp]);

    async function _createTicket(values, actions) {
        let infosTicket = {};

        infosTicket = {
            troubleDescription: values.commentaire,
            troubleDetectionTime: moment(Date()).format(),
            referenceIMB: values.referenceImb,
            refExploitation: values.referenceImb,
            typeInfrastructure: "IMB",
            malfacons: [
                {
                    idMalfacon : values.typeMalfacon.id,
                    libelle : values.typeMalfacon.libelle
                }
            ],
            idTiers: values.tiers.id,
            referenceInterne: values.referenceInterne,
            commentaireInterne:values.commentaireInterne,
            estLogementProche:values.estLogementProche.value,
            adresse: values.numeroVoie + ' ' + values.libelleVoie + ' ' + values.codePostal + ' ' + values.ville,
            presenceJourInstallation: values.presenceInstallation.value,
            referencePTO: values.referencePTO.referencePTO,
            statutContact: values.statutContact.label,
            estOccupantContact: values.estOccupant.value,
            estProprietaireContact: values.estOccupant.value,
            autorisationProprietaire: values.accordProprietaire.value,
            rgpd: values.rgpd,
            coordonneesContact: {
                nom: values.nom,
                prenom: values.prenom,
                telephone: values.telephone,
                mail: values.mail,
            },
        };

        if(values.dateInstallation !== null) {
            infosTicket.dateInstallation = moment(values.dateInstallation).format('YYYY-MM-DDTHH:mm:ss');
        }

        if(values.informe) {
            infosTicket.coordonneesContactSecondaire = {
                nom: values.nomSecondaire,
                prenom: values.prenomSecondaire,
                telephone: values.telephoneSecondaire,
                mail: values.mailSecondaire,
            };
        }

        const fichiers = {
            'piecesJointes' : values.piecesJointes,
            'piecesJointesSupplementaires' : values.piecesJointesSupplementaires,
        };

        postCreateTicket(infosTicket, fichiers, values.typeMalfacon.id).then(response => {
            if(response && response.data.malfacons.length > 0) {
                actions.setSubmitting(false);
                setNumeroTicket(response.data.malfacons[0].response.troubleTicketKey);
                setLoading(false)
                setOpenModal(true)
            } else {
                actions.setSubmitting(false);
                setLoading(false)
                enqueueSnackbar('Une erreur est survenue lors de l\'enregistrement de votre malfaçon. Veuillez ressayer plus tard.', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
            }
        }).catch(function (error) {
            actions.setSubmitting(false);
            setLoading(false)
            enqueueSnackbar('Une erreur est survenue lors de l\'enregistrement de votre malfaçon. Veuillez ressayer plus tard.', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        });
    }

    function _handleSubmit(values, actions) {
        if (isLastStep) {
            setLoading(true);
            _createTicket(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
            window.scrollTo(0, 0);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
        window.scrollTo(0, 0);
    }

    return (
        <Grid
            component="main"
            container
            className={classes.root}
            alignItems="center"
            direction="column"
        >
            <Fade in={loading}>
                <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="secondary"/>
                </Backdrop>
            </Fade>
            <Modal
              isOpen={openModal}
              theme="validate"
              dialogHeader="Prise en compte de votre déclaration"
              dialogFooter={
                <>
                  <Button
                    className="button-secondary"
                    onClick={() => {
                        setOpenModal(false)
                        window.location.reload()
                    }}
                    variant="contained"
                  >
                      Fermer
                  </Button>
                </>
              }
            >
                <Typography>
                    Nous avons bien pris en compte votre demande.
                </Typography>
                <Typography>
                    Un ticket a été créé dans nos services, son numéro est le suivant : <b>{numeroTicket}</b>.
                </Typography>
                <Typography>
                    Un email vous sera envoyé à l'adresse mail renseignée lors de votre déclaration de malfaçon.
                </Typography>
            </Modal>
            <Grid item className={classes.content}>
                <Grid container justify="space-around" spacing={1}>
                    <Grid item xs={12} sm={10} md={8} className={classes.iconGrid}>
                        <img alt="Altitude Infrastructure" src="/img/Dioptic.png"
                             className={classes.altiIcon}/>
                        {/*{activeDsp &&*/}
                        {/*<LogoDsp activeDsp={activeDsp}/>*/}
                        {/*}*/}
                    </Grid>
                </Grid>

                <Formik
                    initialValues={formInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                >
                    {({isSubmitting}) => (
                        <Form id={formId} className={classes.form}>
                            {activeStep > 0 && activeStep < 5 && (
                              <Breadcrumb menu={stepMenu} value={activeStep} />
                            )}
                            {_renderStepContent(activeStep, activeDsp, listTiers, listTypesMalfacons, ptosList, setPtosList)}
                            {(activeStep < 5) &&
                            <Grid item xs={12} className={classes.buttonField}>
                                <Grid container justify="space-around" spacing={1}>
                                    {activeStep !== 0 && (
                                        <Grid item xs={4}>
                                            <Button className={classes.button} fullWidth variant="contained"
                                                    onClick={_handleBack}
                                                    variant="contained"
                                            ><i className={classes.button}/>Retour</Button>
                                        </Grid>
                                    )}
                                    <Grid item xs={4}>
                                        <Button disabled={isSubmitting} className={classes.button} fullWidth
                                                variant="contained" type="submit"
                                        ><i className={classes.button}/> {isLastStep ? 'Je confirme' : 'Suivant'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            }
                        </Form>
                    )}
                </Formik>
                <Grid container justify="center" className={classes.iconGrid}>
                    <p className={classes.copyright}>Déclaration de malfaçons sur un raccordement client -
                        © {new Date().getFullYear()} Altitude
                        Infra.</p>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default App;
