/* eslint-disable no-unused-expressions */
import React, {useEffect} from 'react';
import propTypes from 'prop-types';
import {CircularProgress, TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

import './AutoComplete.scss';

const AutoComplete = ({
  // global use radioGroup
  name, size, key, options, value, getOptionLabel, optionLabel, onChange, onChangeTextField, freeSolo, loading, loadingText, noOptionsText, renderInput,
  // case Formik's Autocomplete
  field, form: { touched: toucheds, errors, setFieldValue }, isFormik,
  // case no Formik
  label, variant, placeholder, error, touched,

  ...props
}) => {

  useEffect(() => {

  }, []);

  return (
    <div>
      <Autocomplete
        {...props}
        id={name}
        size={size}
        key={key}
        options={options}
        value={isFormik ? field.value : value}
        // getOptionLabel={option => option[optionLabel]}
        getOptionLabel={(option) => (option ? option[optionLabel] : '')}
        onChange={(e, actionMeta, reason) => {
          onChange ? onChange(e, actionMeta, reason) : null;
          isFormik ? setFieldValue(isFormik ? field.name : name, actionMeta || '') : null;
        }}
        freeSolo={freeSolo}
        loading={loading}
        loadingText={loadingText}
        noOptionsText={noOptionsText}
        onInputChange={(e, actionMeta, reason) => reason === 'clear' && isFormik && setFieldValue(isFormik ? field.name : name, '')}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant={variant}
            placeholder={placeholder}
            onChange={(e) => {
              onChangeTextField ? onChangeTextField(e) : null;
              isFormik ? setFieldValue(isFormik ? field.name : name, '') : null;
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      {
        isFormik ?
          errors[field.name] && toucheds[field.name] && (
          <div
            className="form-control-errorLabel"
          >{errors[field.name] ? errors[field.name] : errors[field.name]}
          </div>
          ) :
          error && touched && <div className="form-control-errorLabel">{error}</div>
      }
    </div>
  );
};

AutoComplete.propTypes = {
  // global use
  name: propTypes.string,
  size: propTypes.string,
  key: propTypes.bool,
  options: propTypes.array.isRequired,
  value: propTypes.any,
  getOptionLabel: propTypes.func,
  optionLabel: propTypes.string,
  onChange: propTypes.func,
  onChangeTextField: propTypes.func,
  freeSolo: propTypes.bool,
  loading: propTypes.bool,
  loadingText: propTypes.string,
  noOptionsText: propTypes.string,
  renderInput: propTypes.func,
  // case Formik's select
  field: propTypes.any,
  form: propTypes.shape({
    touched: propTypes.any,
    errors: propTypes.any,
    setFieldValue: propTypes.func,
  }),
  isFormik: propTypes.bool,
  label: propTypes.string,
  variant: propTypes.string,
  placeholder: propTypes.string,
};

AutoComplete.defaultProps = {
  name: '',
  size: 'small',
  key: false,
  value: null,
  getOptionLabel: () => {},
  optionLabel: null,
  onChange: null,
  onChangeTextField: null,
  freeSolo: true,
  loading: false,
  loadingText: 'Chargement ...',
  noOptionsText: 'Aucune donnée',
  renderInput: () => {},
  // case Formik's select
  field: null,
  form: {},
  isFormik: false,
  label: '',
  variant: 'outlined',
  placeholder: '',
};

export default AutoComplete;
